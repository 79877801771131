import {AxiosResponse, AxiosError, AxiosRequestConfig} from "axios";
import * as Sentry from '@sentry/vue';
import axios from "axios";
import {TaskDataType} from "@/shared/api";
import {ChangeFieldValueType} from "@/shared/api";


export interface IhttpResponse {
    readonly status: number,
    readonly body: { [key: string]: any; }
}

/**
 * Extend default error handling: return any error as standard response.
 * All http errors by backend should handle on higher level
 */
axios.interceptors.response.use((response: AxiosResponse) => {
    return response;
}, (error: AxiosError) => {
    return error.response;
});

axios.defaults.withCredentials = true;

/**
 * HTTP get request
 * @param url
 * @param queryParams
 * @param headers
 * @param no_auth
 */
export const getRequest = async (
    url: string,
    queryParams: { [key: string]: string; } = {},
    headers: { [key: string]: string; } = {},
    no_auth = false
): Promise<IhttpResponse> => {
    const token = localStorage.getItem('current_user')

    if (no_auth === false && (token !== undefined || token !== null) && !(url.includes("users/login"))){
        headers['Authorization'] = `Token ${token}`;
    }
    const config: AxiosRequestConfig = {
        url: url,
        method: 'get',
        headers: headers,
        params: new URLSearchParams(queryParams),
        withCredentials: true
    };

    try {
        const response = await axios(config);
        if (response === undefined){
            throw new Error("Сервер не отвечает")
        }
        return {status: response.status, body: response.data} as IhttpResponse;
    } catch (error) {
        // Отправляем информацию об ошибке в Sentry
        Sentry.withScope((scope) => {
            scope.setExtra('url', url);
            scope.setExtra('headers', headers);
            if (error instanceof Error) {
                Sentry.captureException(error); // Сохраняем ошибку в Sentry
            } else {
                Sentry.captureMessage('Unknown error during postRequest');
            }
        });

        // Пробрасываем ошибку дальше или обрабатываем её
        throw error
    }


}


/**
 * HTTP post request
 * @param url
 * @param body
 * @param headers
 * @param no_auth
 */
export const postRequest = async (
    url: string,
    body: unknown,
    headers: { [key: string]: string; } = {},
    no_auth = false,
): Promise<IhttpResponse> => {
    const token = localStorage.getItem('current_user')

    if (no_auth === false && (token !== undefined || token !== null) && !(url.includes("users/login"))){
        headers['Authorization'] = `Token ${token}`;
    }

    const config: AxiosRequestConfig = {
        url: url,
        method: 'post',
        headers: headers,
        data: body,
    };

    try {
        const response = await axios(config);

        if (response === undefined){
            throw new Error("Сервер не отвечает")
        }

        return {status: response.status, body: response.data} as IhttpResponse;
    } catch (error) {
        // Отправляем информацию об ошибке в Sentry
        Sentry.withScope((scope) => {
            scope.setExtra('url', url);
            scope.setExtra('body', body);
            scope.setExtra('headers', headers);
            if (error instanceof Error) {
                Sentry.captureException(error); // Сохраняем ошибку в Sentry
            } else {
                Sentry.captureMessage('Unknown error during postRequest');
            }
        });

        // Пробрасываем ошибку дальше или обрабатываем её
        throw error; // Можно пробросить ошибку дальше, чтобы она могла быть обработана на верхнем уровне
    }
}

/**
 * HTTP patch request
 * @param url
 * @param body
 * @param headers
 * @param no_auth
 */
export const patchRequest = async (
    url: string,
    body: unknown,
    headers: { [key: string]: string; } = {},
    no_auth = false,
): Promise<IhttpResponse> => {
    const token = localStorage.getItem('current_user')

    if (no_auth === false && (token !== undefined || token !== null) && !(url.includes("users/login"))){
        headers['Authorization'] = `Token ${token}`;
    }
    const config: AxiosRequestConfig = {
        url: url,
        method: 'patch',
        headers: headers,
        data: body
    };

   try {
       const response = await axios(config);

       if (response === undefined){
           throw new Error("Сервер не отвечает")
       }

       if (response.status === 404) {
           throw new Error("Что-то пошло не так")
       }

       return {status: response.status, body: response.data} as IhttpResponse;
   } catch (error) {
       // Отправляем информацию об ошибке в Sentry
       Sentry.withScope((scope) => {
           scope.setExtra('url', url);
           scope.setExtra('body', body);
           scope.setExtra('headers', headers);
           if (error instanceof Error) {
               Sentry.captureException(error); // Сохраняем ошибку в Sentry
           } else {
               Sentry.captureMessage('Unknown error during postRequest');
           }
       });

       // Пробрасываем ошибку дальше или обрабатываем её
       throw error;
   }
}


/**
 * HTTP patch request
 * @param url
 * @param body
 * @param headers
 * @param no_auth
 */
export const putRequest = async (
    url: string,
    body: unknown,
    headers: { [key: string]: string; } = {},
    no_auth = false,
): Promise<IhttpResponse> => {
    const token = localStorage.getItem('current_user')

    if (no_auth === false && (token !== undefined || token !== null) && !(url.includes("users/login"))){
        headers['Authorization'] = `Token ${token}`;
    }

    const config: AxiosRequestConfig = {
        url: url,
        method: 'put',
        headers: headers,
        data: body
    };
    try {
        const response = await axios(config);
        if (response === undefined){
            throw new Error("Сервер не отвечает")
        }
        return {status: response.status, body: response.data} as IhttpResponse;
    } catch (error) {
        Sentry.withScope((scope) => {
            scope.setExtra('url', url);
            scope.setExtra('body', body);
            scope.setExtra('headers', headers);
            if (error instanceof Error) {
                Sentry.captureException(error); // Сохраняем ошибку в Sentry
            } else {
                Sentry.captureMessage('Unknown error during postRequest');
            }
        });

        // Пробрасываем ошибку дальше или обрабатываем её
        throw error;
    }
}


export const putTask = async (id: number, values: ChangeFieldValueType[]): Promise<TaskDataType> => {
    const url = `${process.env.VUE_APP_TASK_RADAR}/task/${id}`;
    const body = { values: values };
    const res: IhttpResponse = await putRequest(url, body);
    if (res.status === 200) {
        return res.body as TaskDataType;
    }
    if (res.status === 401) {
        throw new Error(JSON.stringify(res.body));
    }
    throw new Error("Сервер не отвечает");
}
