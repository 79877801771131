import {TaskDataType} from "@/shared/api";
import {HeaderAxios, ResponseAxios} from "@/shared/api";
import HttpClient from "@/app/config/axiosConfig/axiosConfig";

interface IProps {
    id: number,
    name: string,
    surname: string,
    patronymic: string,
}

export const editUser = (data: IProps, user_id: number) => {
    let responseObj = {} as ResponseAxios
    let headers = {} as HeaderAxios

    const token = localStorage.getItem('current_user')

    if (!token) {
        return (responseObj.error = 'Не авторизован')
    }

    headers['Authorization'] = `Token ${token}`
    return HttpClient({
        method: 'PUT',
        data: data,
        url: `/users/${user_id}`,
        withCredentials: true,
        headers
    })
        .then((resp: any) => {
            if (resp.status === 200) {
                return resp.data as TaskDataType[]
            } else {
                console.error(`Не удалось найти задачи`)
            }
        })
        .catch((err: any) => {
            return err.response
        })

}
