<template>
    <div v-if="type === commentType.common || (type === commentType.textfield && isVisible)" class="task-full-border">
        <div class="task-full-title">
            <div class="title-description-div" style="word-break: break-all">
                <slot v-if="!isEditable && inputType !== commentType.catalog">
                    <div class="title-description-img">
                        <img :src="getImgUrl(constName)" alt="" />
                    </div>

                    <div>
                        <p class="title-task-description">{{ title }}</p>

                        <p
                            v-if="type === commentType.textfield"
                            class="h3-primary"
                            style="white-space: pre-line"
                            v-html="replaceLink(element)"
                        ></p>
                        <p v-else class="h3-primary" style="white-space: pre-line">{{ element }}</p>
                        <div
                            v-if="title === 'Логин в системе'"
                            class="title-task__search"
                            @click="loginSearchClick(element)"
                        >
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M11.5625 20.375C16.1534 20.375 19.875 16.6534 19.875 12.0625C19.875 7.47163 16.1534 3.75 11.5625 3.75C6.97163 3.75 3.25 7.47163 3.25 12.0625C3.25 16.6534 6.97163 20.375 11.5625 20.375Z"
                                    stroke="#3881F0"
                                    stroke-width="1.86501"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M20.75 21.25L17.6875 18.1875"
                                    stroke="#3881F0"
                                    stroke-width="1.86501"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                    </div>
                </slot>

                <slot v-else-if="isEditable && inputType !== commentType.catalog">
                    <div class="task-input__container">
                        <p class="h3-primary" style="white-space: pre-line">
                            {{ title }}
                        </p>
                        <slot v-if="title === 'Претензии к работе авто'">
                            <textarea
                                class="task-input"
                                @change="updateInputField(id, element, inputType)"
                                :type="inputType"
                                @input="resize()"
                                ref="textarea"
                                :placeholder="title"
                                contentEditable
                                :disabled="isTaskClosed"
                                v-model="element"
                            ></textarea>
                        </slot>
                        <slot v-else>
                            <input
                                class="task-input"
                                @change="updateInputField(id, element, inputType)"
                                :type="inputType"
                                :disabled="isTaskClosed"
                                :placeholder="title"
                                v-model="element"
                            />
                        </slot>
                    </div>
                </slot>

                <slot v-else-if="isEditable && inputType === commentType.catalog">
                    <div class="task-input__container">
                        <p class="h3-primary" style="white-space: pre-line">
                            {{ title }}
                        </p>
                        <select
                            @change="updateInputField(id, selected, inputType)"
                            v-model="selected"
                            name="select"
                            class="task-input"
                        >
                            <option v-for="catalog in catalogs" :value="catalog">{{ catalog }}</option>
                        </select>
                    </div>
                </slot>
                <slot v-else-if="!isEditable && inputType === commentType.catalog">
                    <div class="title-description-img">
                        <img :src="getImgUrl(constName)" alt="" />
                    </div>

                    <div>
                        <p class="title-task-description">{{ title }}</p>
                        <p class="h3-primary" style="white-space: pre-line">
                            {{ element }}
                        </p>
                    </div>
                </slot>
            </div>
        </div>
    </div>

    <div v-else-if="type === commentType.catalog && isVisible" class="task-full--border">
        <slot v-if="!isEditable && inputType === commentType.catalog">
            <div class="task-full-title">
                <div class="title-description-div">
                    <div class="title-description-img">
                        <img :src="getImgUrl(constName)" alt="" />
                    </div>
                    <div>
                        <p class="title-task-description">{{ title }}</p>
                        <p class="h3-primary">{{ element }}</p>
                    </div>
                </div>
            </div>
        </slot>
    </div>

    <div v-else-if="type === commentType.money && isVisible" class="task-full-border">
        <div class="task-full-title">
            <div class="title-description-div">
                <div class="title-description-img">
                    <img :src="getImgUrl(constName)" alt="" />
                </div>
                <div>
                    <p class="title-task-description">{{ title }}</p>
                    <p class="h3-primary">{{ element }} руб</p>
                </div>
            </div>
        </div>
    </div>

    <div v-else-if="type === commentType.phone && isVisible" class="task-full-border">
        <div class="task-full-title">
            <div class="title-description-div">
                <div class="title-description-img">
                    <img :src="getImgUrl(constName)" alt="" />
                </div>
                <div>
                    <p class="title-task-description">{{ title }}</p>
                    <p class="h3-primary task-full__link">
                        <a :href="'tel:' + phone" style="color: #336fee">
                            {{ value }}
                        </a>
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div v-else-if="type === commentType.address && isVisible" class="task-full-border">
        <div class="task-full-title">
            <div class="title-description-div">
                <div class="title-description-img">
                    <img :src="getImgUrl(constName)" alt="" />
                </div>
                <div>
                    <p class="title-task-description">{{ title }}</p>
                    <p class="h3-primary task-full__link" @click="goToMapLink(element)">
                        {{ element }}
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div v-else-if="type === commentType.checkmark && isVisible" class="task-full-border-zero">
        <div class="task-full-title" style="padding-top: 14px; padding-bottom: 14px">
            <div class="title-description-div" style="align-self: auto; align-items: center">
                <input
                    :disabled="title === installation_completed___end || !editPermission || isTaskClosed"
                    type="checkbox"
                    :checked="element"
                    class="custom-checkbox"
                    :name="`checkbox-id-${constName}`"
                    :id="`checkbox-id-${constName}`"
                    :value="element"
                    @change="toggleCheckbox(id, !element)"
                />
                <label :for="`checkbox-id-${constName}`">{{ title }}</label>
            </div>
        </div>
    </div>

    <div v-else-if="type === commentType.note && isVisible" class="task-full-border">
        <div class="task-full-title">
            <div class="title-description-div title-description-note" style="word-break: break-all">
                <div>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M9 16.5C13.125 16.5 16.5 13.125 16.5 9C16.5 4.875 13.125 1.5 9 1.5C4.875 1.5 1.5 4.875 1.5 9C1.5 13.125 4.875 16.5 9 16.5Z"
                            stroke="#9F9F9F"
                            stroke-width="1.4"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M9 6V9.75"
                            stroke="#9F9F9F"
                            stroke-width="1.4"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M8.99585 12H9.00333"
                            stroke="#9F9F9F"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </div>
                <div>
                    <p class="h3-primary" style="white-space: pre-line">
                        {{ element }}
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div v-else-if="type === commentType.file && isVisible" class="task-full-border">
        <div class="task-full-title">
            <div class="title-description-div">
                <div style="width: 100%">
                    <p class="h3-primary">{{ title }}</p>
                    <label class="input-file">
                        <input
                            :disabled="!editPermission || isTaskClosed"
                            @change="addNewImageInTask"
                            multiple
                            type="file"
                            name="file"
                        />
                        <span>
                            <svg
                                width="21"
                                height="20"
                                viewBox="0 0 21 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M17.4242 8.48321C17.3091 8.36585 17.1529 8.29993 16.99 8.29993C16.8272 8.29993 16.671 8.36585 16.5558 8.48321L9.86606 15.3308C9.5809 15.6214 9.24236 15.852 8.86977 16.0094C8.49717 16.1667 8.09782 16.2477 7.69452 16.2477C6.88001 16.2478 6.09883 15.918 5.52284 15.3311C4.94686 14.7441 4.62324 13.948 4.62318 13.1178C4.62312 12.2877 4.94663 11.4915 5.52254 10.9045L12.0016 4.27414C12.348 3.92665 12.8152 3.73278 13.3013 3.73473C13.7873 3.73669 14.253 3.93433 14.5967 4.2846C14.9405 4.63486 15.1345 5.10938 15.1365 5.60478C15.1386 6.10019 14.9484 6.57634 14.6076 6.92953L8.12853 13.5599C8.01168 13.674 7.85612 13.7377 7.6943 13.7377C7.53248 13.7377 7.37692 13.674 7.26007 13.5599C7.14493 13.4425 7.08025 13.2833 7.08025 13.1173C7.08025 12.9513 7.14493 12.7921 7.26007 12.6747L13.0285 6.76866C13.1404 6.6506 13.2023 6.49247 13.2009 6.32834C13.1995 6.16422 13.1349 6.00721 13.021 5.89115C12.9072 5.77509 12.7531 5.70926 12.5921 5.70783C12.431 5.7064 12.2759 5.7695 12.1601 5.88353L6.39161 11.7896C6.22048 11.964 6.08473 12.171 5.99211 12.3989C5.8995 12.6268 5.85183 12.871 5.85183 13.1176C5.85183 13.3643 5.8995 13.6085 5.99211 13.8364C6.08473 14.0642 6.22048 14.2712 6.39161 14.4456C6.74269 14.7871 7.20933 14.9776 7.69461 14.9776C8.17989 14.9776 8.64652 14.7871 8.9976 14.4456L15.476 7.81466C16.0399 7.22518 16.3531 6.43304 16.3479 5.60983C16.3428 4.78662 16.0196 3.99863 15.4484 3.41657C14.8772 2.8345 14.104 2.50522 13.2963 2.50006C12.4886 2.4949 11.7114 2.81428 11.1331 3.38901L4.65408 10.0194C3.84776 10.8411 3.39478 11.9557 3.39478 13.1179C3.39478 14.2801 3.84776 15.3947 4.65408 16.2165C5.46039 17.0383 6.554 17.5 7.6943 17.5C8.83461 17.5 9.92821 17.0383 10.7345 16.2165L17.4242 9.37084C17.4816 9.31267 17.5272 9.24352 17.5582 9.16735C17.5893 9.09119 17.6053 9.00951 17.6053 8.92702C17.6053 8.84454 17.5893 8.76286 17.5582 8.6867C17.5272 8.61053 17.4816 8.54138 17.4242 8.48321Z"
                                    fill="#009bf1"
                                />
                            </svg>

                            Загрузить файл
                        </span>
                    </label>
                    <slot v-if="(imageLoaded && imageLoaded.length > 0) || fileListPreloader.length > 0">
                        <p class="title-task-description" style="margin-bottom: 8px">Загруженные фотографии</p>
                        <div class="task-full--imgs">
                            <div v-for="(_, id) in fileListPreloader" :key="id" class="attachment-div">
                                <img
                                    class="task-full__downloads task-full__downloads--preloader"
                                    src="@/assets/img/Chat/preloader.svg"
                                    alt=""
                                />
                            </div>
                            <slot v-for="file in imageLoaded">
                                <img
                                    v-if="imgExtension.includes(getFileExtension(file))"
                                    class="task-full__downloads"
                                    @click="selectImage(`${url}${file}`)"
                                    :src="`${url}${file}?is_preview=1`"
                                    alt=""
                                />
                            </slot>
                        </div>
                        <p class="title-task-description" style="margin-bottom: 8px; margin-top: 12px">Файлы</p>
                        <div>
                            <slot v-for="file in imageLoaded">
                                <slot v-if="!imgExtension.includes(getFileExtension(file))">
                                    <div class="task-file" @click.prevent="downloadItem(file)">
                                        <div class="task-file--image">
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M15.2244 8.88841H13.448C11.9912 8.88841 10.8049 7.70209 10.8049 6.24532V4.46892C10.8049 4.13085 10.5283 3.85425 10.1902 3.85425H7.584C5.69081 3.85425 4.16028 5.08359 4.16028 7.27797V12.724C4.16028 14.9183 5.69081 16.1477 7.584 16.1477H12.4153C14.3085 16.1477 15.839 14.9183 15.839 12.724V9.50308C15.839 9.16501 15.5624 8.88841 15.2244 8.88841ZM10.1718 12.3244L8.94242 13.5538C8.8994 13.5968 8.84408 13.6337 8.78875 13.6521C8.73343 13.6767 8.67811 13.689 8.61665 13.689C8.55518 13.689 8.49986 13.6767 8.44454 13.6521C8.39537 13.6337 8.34619 13.5968 8.30931 13.5599C8.30316 13.5538 8.29702 13.5538 8.29702 13.5476L7.06767 12.3183C6.88942 12.14 6.88942 11.845 7.06767 11.6667C7.24593 11.4885 7.54097 11.4885 7.71923 11.6667L8.15564 12.1154V9.53996C8.15564 9.28794 8.36463 9.07896 8.61665 9.07896C8.86866 9.07896 9.07765 9.28794 9.07765 9.53996V12.1154L9.52021 11.6729C9.69847 11.4946 9.99351 11.4946 10.1718 11.6729C10.35 11.8511 10.35 12.1462 10.1718 12.3244Z"
                                                    fill="#009BF1"
                                                />
                                                <path
                                                    d="M13.3377 8.04013C13.9216 8.04628 14.733 8.04628 15.4276 8.04628C15.7779 8.04628 15.9623 7.63445 15.7165 7.38858C14.8313 6.4973 13.2455 4.89301 12.3358 3.9833C12.0838 3.73128 11.6473 3.90339 11.6473 4.25375V6.39896C11.6473 7.29638 12.4095 8.04013 13.3377 8.04013Z"
                                                    fill="#009BF1"
                                                />
                                            </svg>
                                        </div>
                                        <div class="task-file--title">{{ getFileName(file) }}</div>
                                    </div>
                                </slot>
                            </slot>
                        </div>
                    </slot>
                </div>
            </div>
        </div>
    </div>

    <div v-else-if="type === commentType.router" class="task-full-border">
        <div class="task-full-title">
            <div class="title-description-div">
                <div class="title-description-img">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M15.3671 6.38498C15.3135 6.37604 15.2509 6.37604 15.1972 6.38498C13.9627 6.34026 12.9788 5.32944 12.9788 4.07711C12.9788 2.79793 14.0075 1.76923 15.2866 1.76923C16.5658 1.76923 17.5945 2.80688 17.5945 4.07711C17.5856 5.32944 16.6016 6.34026 15.3671 6.38498Z"
                            stroke="#9F9F9F"
                            stroke-width="1.35"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M14.4455 12.8971C15.671 13.1029 17.0218 12.8882 17.97 12.2531C19.2313 11.4122 19.2313 10.0346 17.97 9.19379C17.0128 8.55868 15.6442 8.34399 14.4187 8.55867"
                            stroke="#9F9F9F"
                            stroke-width="1.35"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M4.60579 6.38498C4.65946 6.37604 4.72208 6.37604 4.77575 6.38498C6.01019 6.34026 6.99417 5.32944 6.99417 4.07711C6.99417 2.79793 5.96547 1.76923 4.6863 1.76923C3.40712 1.76923 2.37842 2.80688 2.37842 4.07711C2.38736 5.32944 3.37134 6.34026 4.60579 6.38498Z"
                            stroke="#9F9F9F"
                            stroke-width="1.35"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M5.52728 12.8971C4.30178 13.1029 2.95104 12.8882 2.00285 12.2531C0.741564 11.4122 0.741564 10.0346 2.00285 9.19379C2.95999 8.55868 4.32861 8.34399 5.55411 8.55867"
                            stroke="#9F9F9F"
                            stroke-width="1.35"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M9.99996 11.6358C9.94628 11.6269 9.88367 11.6269 9.83 11.6358C8.59555 11.5911 7.61157 10.5803 7.61157 9.32796C7.61157 8.04879 8.64028 7.02008 9.91945 7.02008C11.1986 7.02008 12.2273 8.05773 12.2273 9.32796C12.2184 10.5803 11.2344 11.6001 9.99996 11.6358Z"
                            stroke="#9F9F9F"
                            stroke-width="1.35"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M7.39689 14.4536C6.13561 15.2945 6.13561 16.672 7.39689 17.5129C8.82813 18.47 11.1718 18.47 12.603 17.5129C13.8643 16.672 13.8643 15.2945 12.603 14.4536C11.1807 13.5054 8.82813 13.5054 7.39689 14.4536Z"
                            stroke="#9F9F9F"
                            stroke-width="1.35"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </div>
                <div>
                    <p class="title-task-description" style="margin-bottom: 4px">{{ title }}</p>
                    {{ router }}
                    <div class="task-profile" v-for="user in users">
                        <div class="task-profile-img">
                            <slot v-if="user.avatar_url">
                                <img :src="path + user.avatar_url + '?is_image=1&avatar_size=64'" />
                            </slot>
                            <slot v-else>
                                <svg
                                    width="28"
                                    height="28"
                                    viewBox="0 0 28 28"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M27.0003 14C27.0003 6.83696 21.1633 0.999954 14.0003 0.999954C6.83725 0.999954 1.00024 6.83696 1.00024 14C1.00024 17.77 2.62525 21.163 5.19925 23.542C5.19925 23.555 5.19925 23.555 5.18625 23.568C5.31625 23.698 5.47225 23.802 5.60225 23.919C5.68025 23.984 5.74525 24.049 5.82325 24.101C6.05725 24.296 6.31725 24.478 6.56425 24.66C6.65525 24.725 6.73325 24.777 6.82425 24.842C7.07125 25.011 7.33125 25.167 7.60425 25.31C7.69525 25.362 7.79925 25.427 7.89025 25.479C8.15025 25.622 8.42325 25.752 8.70925 25.869C8.81325 25.921 8.91725 25.973 9.02125 26.012C9.30725 26.129 9.59325 26.233 9.87925 26.324C9.98325 26.363 10.0873 26.402 10.1913 26.428C10.5033 26.519 10.8153 26.597 11.1273 26.675C11.2183 26.701 11.3093 26.727 11.4133 26.74C11.7773 26.818 12.1413 26.87 12.5183 26.909C12.5703 26.909 12.6223 26.922 12.6743 26.935C13.1163 26.974 13.5583 27 14.0003 27C14.4423 27 14.8843 26.974 15.3133 26.935C15.3653 26.935 15.4173 26.922 15.4693 26.909C15.8463 26.87 16.2103 26.818 16.5743 26.74C16.6653 26.727 16.7563 26.688 16.8603 26.675C17.1723 26.597 17.4973 26.532 17.7963 26.428C17.9003 26.389 18.0043 26.35 18.1083 26.324C18.3943 26.22 18.6933 26.129 18.9663 26.012C19.0703 25.973 19.1743 25.921 19.2783 25.869C19.5513 25.752 19.8243 25.622 20.0973 25.479C20.2013 25.427 20.2923 25.362 20.3833 25.31C20.6433 25.154 20.9033 25.011 21.1633 24.842C21.2543 24.79 21.3323 24.725 21.4233 24.66C21.6833 24.478 21.9303 24.296 22.1643 24.101C22.2423 24.036 22.3073 23.971 22.3853 23.919C22.5283 23.802 22.6713 23.685 22.8013 23.568C22.8013 23.555 22.8013 23.555 22.7883 23.542C25.3753 21.163 27.0003 17.77 27.0003 14ZM20.4223 20.461C16.8993 18.095 11.1273 18.095 7.57825 20.461C7.00625 20.838 6.53825 21.28 6.14825 21.761C4.17225 19.759 2.95025 17.016 2.95025 14C2.95025 7.90296 7.90325 2.94996 14.0003 2.94996C20.0973 2.94996 25.0503 7.90296 25.0503 14C25.0503 17.016 23.8283 19.759 21.8523 21.761C21.4753 21.28 20.9943 20.838 20.4223 20.461Z"
                                        fill="#9F9F9F"
                                    />
                                    <path
                                        d="M14 8.08501C11.585 8.08501 9.625 10.045 9.625 12.46C9.625 14.8283 11.48 16.7533 13.9417 16.8233C13.9767 16.8233 14.0233 16.8233 14.0467 16.8233C14.07 16.8233 14.105 16.8233 14.1283 16.8233C14.14 16.8233 14.1517 16.8233 14.1517 16.8233C16.5083 16.7417 18.3633 14.8283 18.375 12.46C18.375 10.045 16.415 8.08501 14 8.08501Z"
                                        fill="#9F9F9F"
                                    />
                                </svg>
                            </slot>
                        </div>

                        <p class="h3-primary" style="white-space: pre-line">
                            {{ user.info.name }} {{ user.info.surname }}
                        </p>
                    </div>
                    <div class="task-profile" v-for="dp in department">
                        <div class="task-profile-img">
                            <slot v-if="dp.avatar_url">
                                <img :src="path + dp.avatar_url + '?is_image=1&avatar_size=64'" />
                            </slot>
                            <slot v-else>
                                <svg
                                    width="28"
                                    height="28"
                                    viewBox="0 0 28 28"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M27.0003 14C27.0003 6.83696 21.1633 0.999954 14.0003 0.999954C6.83725 0.999954 1.00024 6.83696 1.00024 14C1.00024 17.77 2.62525 21.163 5.19925 23.542C5.19925 23.555 5.19925 23.555 5.18625 23.568C5.31625 23.698 5.47225 23.802 5.60225 23.919C5.68025 23.984 5.74525 24.049 5.82325 24.101C6.05725 24.296 6.31725 24.478 6.56425 24.66C6.65525 24.725 6.73325 24.777 6.82425 24.842C7.07125 25.011 7.33125 25.167 7.60425 25.31C7.69525 25.362 7.79925 25.427 7.89025 25.479C8.15025 25.622 8.42325 25.752 8.70925 25.869C8.81325 25.921 8.91725 25.973 9.02125 26.012C9.30725 26.129 9.59325 26.233 9.87925 26.324C9.98325 26.363 10.0873 26.402 10.1913 26.428C10.5033 26.519 10.8153 26.597 11.1273 26.675C11.2183 26.701 11.3093 26.727 11.4133 26.74C11.7773 26.818 12.1413 26.87 12.5183 26.909C12.5703 26.909 12.6223 26.922 12.6743 26.935C13.1163 26.974 13.5583 27 14.0003 27C14.4423 27 14.8843 26.974 15.3133 26.935C15.3653 26.935 15.4173 26.922 15.4693 26.909C15.8463 26.87 16.2103 26.818 16.5743 26.74C16.6653 26.727 16.7563 26.688 16.8603 26.675C17.1723 26.597 17.4973 26.532 17.7963 26.428C17.9003 26.389 18.0043 26.35 18.1083 26.324C18.3943 26.22 18.6933 26.129 18.9663 26.012C19.0703 25.973 19.1743 25.921 19.2783 25.869C19.5513 25.752 19.8243 25.622 20.0973 25.479C20.2013 25.427 20.2923 25.362 20.3833 25.31C20.6433 25.154 20.9033 25.011 21.1633 24.842C21.2543 24.79 21.3323 24.725 21.4233 24.66C21.6833 24.478 21.9303 24.296 22.1643 24.101C22.2423 24.036 22.3073 23.971 22.3853 23.919C22.5283 23.802 22.6713 23.685 22.8013 23.568C22.8013 23.555 22.8013 23.555 22.7883 23.542C25.3753 21.163 27.0003 17.77 27.0003 14ZM20.4223 20.461C16.8993 18.095 11.1273 18.095 7.57825 20.461C7.00625 20.838 6.53825 21.28 6.14825 21.761C4.17225 19.759 2.95025 17.016 2.95025 14C2.95025 7.90296 7.90325 2.94996 14.0003 2.94996C20.0973 2.94996 25.0503 7.90296 25.0503 14C25.0503 17.016 23.8283 19.759 21.8523 21.761C21.4753 21.28 20.9943 20.838 20.4223 20.461Z"
                                        fill="#9F9F9F"
                                    />
                                    <path
                                        d="M14 8.08501C11.585 8.08501 9.625 10.045 9.625 12.46C9.625 14.8283 11.48 16.7533 13.9417 16.8233C13.9767 16.8233 14.0233 16.8233 14.0467 16.8233C14.07 16.8233 14.105 16.8233 14.1283 16.8233C14.14 16.8233 14.1517 16.8233 14.1517 16.8233C16.5083 16.7417 18.3633 14.8283 18.375 12.46C18.375 10.045 16.415 8.08501 14 8.08501Z"
                                        fill="#9F9F9F"
                                    />
                                </svg>
                            </slot>
                        </div>

                        <p class="h3-primary" style="white-space: pre-line">
                            {{ dp.name }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { COMMENT_TYPE } from '@/constants/constants';
import { getDepartmentById, getUserById } from '@/store/interaction/userInteraction';
import Preloader from '@/shared/ui/preloader';
import { postCommentAttachment } from '@/axios_requests/post_attachments';
import { updateTask } from '@/axios_requests/update_task';
import { saveAs } from 'file-saver';
import { getCatalogById } from '@/axios_requests/get_catalog';
import { TASK } from '@/utils/const';
import { compressImage } from '@/helpers/fileMethod';
import { getFileName } from '@/utils/message';
import { getGeoSearch } from '@/axios_requests/post_geosearch';
import { yandexUtils } from '@/shared/lib/YandexUtils/YandexUtils';

export default {
    name: 'FieldItemWrap',
    components: { Preloader },
    props: {
        title: {
            type: String,
            required: true,
        },
        element: {
            type: String | Number | Object | Array,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
        constName: {
            type: String,
            default: 'Тип установки',
        },
        inputType: {
            type: String,
        },
        id: {
            type: Number,
        },
        taskId: {
            type: Number,
        },
        isEditable: {
            type: Boolean,
        },
        catalogs_id: {
            type: Number | null,
        },
        isVisible: {
            type: Boolean,
        },
        rerender: {
            type: Boolean,
        },
        editPermission: {
            type: Boolean,
        },
        isTaskClosed: {
            type: Boolean,
            required: true,
        },
    },
    setup() {
        return {
            getFileName,
        };
    },
    data() {
        return {
            path: process.env.VUE_APP_TASK_RADAR,
            users: [],
            department: [],
            commentType: COMMENT_TYPE,
            value: '',
            catalogs: [],
            selected: '',
            fileListPreloader: [],
            url: `${process.env.VUE_APP_TASK_RADAR}`,
            attachments: this.$store.getters.getAllAttachments,
            imageLoadedId: [],
            installation_completed___end: TASK.installation_completed___end,
            imgExtension: ['jpg', 'jpeg', 'png', 'svg'],
        };
    },
    async mounted() {
        if (this.catalogs_id) {
            const catalogValue = await getCatalogById(this.catalogs_id);
            this.selected = this.element;

            this.catalogs = catalogValue.items.map((item) => {
                return item.value[0];
            });
        }

        this.resize();
    },
    computed: {
        imageLoaded() {
            if (this.type === COMMENT_TYPE.file) {
                if (this.element) {
                    const imgs = this.element.split(', ');
                    this.imageLoadedId = imgs;

                    const arr = [];
                    imgs.forEach((item) => {
                        const res = this.getMessageById(item);
                        if (res) {
                            arr.push(res);
                        }
                    });

                    return arr;
                }

                return [];
            }
        },
        phone() {
            if (this.type === COMMENT_TYPE.phone) {
                if (this.element.split('')[0] === '8') {
                    return (this.value = this.element);
                } else if (this.element.split('').includes('+')) {
                    return (this.value = this.element);
                } else {
                    return (this.value = '+' + this.element);
                }
            }
        },
        router() {
            if (this.type === COMMENT_TYPE.router) {
                this.users.length = 0;
                this.department.length = 0;

                if (this.element && this.element.users) {
                    this.element.users.forEach((user) => {
                        const res = getUserById(Number(user));

                        if (res) {
                            this.users.push(res);
                        }
                    });
                }

                if (this.element && this.element.departments) {
                    this.element.departments.forEach((department) => {
                        const res = getDepartmentById(Number(department));

                        if (res) {
                            this.department.push(res);
                        }
                    });
                }
            }
        },
    },
    methods: {
        async updateInputField(id, value, inputType) {
            let resultValue = value;

            if (inputType === 'number') {
                resultValue = Number(value);
            }

            updateTask(this.taskId, [{ field_id: id, value: resultValue }]);
        },
        async toggleCheckbox(id, value) {
            updateTask(this.taskId, [{ field_id: id, value }]);
        },
        resize() {
            let element = this.$refs['textarea'];

            if (element) {
                element.style.height = '18px';
                element.style.height = element.scrollHeight + 'px';
            }
        },
        selectImage(url) {
            this.$emit('openImageFullSize', url);
        },
        downloadItem(file) {
            saveAs(this.url + file, this.getFileName(file));
        },
        replaceLink(message) {
            message = message.replaceAll(/&nbsp;/g, ' ');

            let urls = message.match(/(https?:\/\/[^\s]+)/g);
            if (!urls || urls.length === 0) return message;

            const originalMessage = message.split(' ').map((word) => {
                if (urls && urls.includes(word)) {
                    return `<a href="${word}">${word}</a>`;
                }

                return word;
            });

            return originalMessage.join(' ');
        },
        getImgUrl(pic) {
            try {
                return require('../../assets/img/TaskFull/update/' + pic + '.svg');
            } catch (err) {
                return require('../../assets/img/TaskFull/update/default.svg');
            }
        },
        async goToMapLink(address) {
            // Проверяем, является ли адрес координатами
            const coordsRegex =
                /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;

            if (coordsRegex.test(address.trim())) {
                const [x, y] = address
                    .split(',')
                    .reverse()
                    .map((coord) => coord.trim());
                window.location = `https://yandex.ru/maps/?pt=${x},${y}&z=18&l=map`;
                return;
            }
            try {
                const response = await yandexUtils.geocode(address);

                if (!response?.length) {
                    alert('Адрес не найден');
                    return;
                }

                const [x, y] = [...response[0].coordinates].reverse();
                window.location = `https://yandex.ru/maps/?pt=${x},${y}&z=18&l=map`;
            } catch (error) {
                alert(`Ошибка при поиске адреса: ${error.message}`);
            }
        },
        cutString(str, n) {
            return str.split(' ').splice(0, n).join(' ');
        },

        async sendFile(file) {
            const res = await postCommentAttachment(file);

            if (res) {
                await this.$store.dispatch('changeMessageAttachments', [res]);

                this.imageLoadedId.push(res.id);

                this.imageLoadedId = this.imageLoadedId.map((item) => Number(item));

                await updateTask(this.taskId, [{ field_id: this.id, value: this.imageLoadedId }]);

                this.fileListPreloader.shift();
            } else {
                // Если сообщение не получилось отправить, то оно будет отправляться фоном
                const fileAndOtherDataForBackAPI = [
                    {
                        dataFile: {
                            file,
                            taskId: this.taskId,
                        },
                        dataField: {
                            fieldId: this.id,
                            imageLoadedId: this.imageLoadedId,
                        },
                    },
                ];

                let db;

                // Открытие базы данных
                const request = indexedDB.open('messagesDB', 1);
                console.log('create 3');

                request.onerror = function (event) {
                    console.error('Ошибка открытия базы данных:', event.target.error);
                };

                request.onsuccess = function (event) {
                    db = event.target.result;
                    // Вы можете вызвать функцию здесь или передать db в функцию, которая будет выполняться после успешного открытия базы данных.
                    addFileAndOtherDataToDB(fileAndOtherDataForBackAPI);
                };

                // Функция для добавления файла в IndexedDB
                function addFileAndOtherDataToDB(data) {
                    if (!db) {
                        console.error('База данных не была открыта.');
                        return;
                    }

                    const transaction = db.transaction(['files'], 'readwrite');
                    const objectStore = transaction.objectStore('files');
                    objectStore.add(data);
                }
            }
        },

        async addNewImageInTask(e) {
            if (e.target.files.length !== 0) {
                for (let i = 0; i < e.target.files.length; i++) {
                    this.fileListPreloader.push('item');
                }

                for (let i = 0; i < e.target.files.length; i++) {
                    let file = e.target.files[i];

                    if (file.type.includes('image')) {
                        try {
                            const compressedFile = await compressImage(file);
                            await this.sendFile(compressedFile);
                        } catch (error) {
                            console.error('Ошибка при сжатии и отправке изображения:', error);
                        }
                    } else {
                        await this.sendFile(file);
                    }
                }
            }
        },
        getMessageById(id) {
            if (id) {
                const img = this.attachments.find((el) => Number(el.id) === Number(id));
                if (img && img.file_path) {
                    return img.file_path;
                }
            }
        },
        getFileExtension(file) {
            if (file) {
                return file.split('.')[file.split('.').length - 1];
            }
        },
        loginSearchClick(element) {
            this.$store.dispatch('changeSearchTask', element);
            this.$store.dispatch('changeReferrerTaskId', this.taskId);
            this.$router.push('/found');
        },
        openFile(file) {
            window.open(`${this.url}${file}`);
        },

        // decodeMap(address) {
        //     let geo = new window.google.maps.Geocoder()
        //     geo.geocode({ address: address }, function (result, status) {
        //         if (status === window.google.maps.GeocoderStatus.OK) {
        //             window.location = `https://yandex.ru/maps/?pt=${result[0].geometry.location.lng()},${result[0].geometry.location.lat()}&z=18&l=map`
        //         } else {
        //             alert('Ошибка в поиске адреса')
        //         }
        //     })
        // },
    },
};
</script>

<style scoped>
.title-description-img {
    display: flex;
    align-self: flex-start;

    padding: 9px;
    margin-right: 12px;
    margin-top: 2px;

    border-radius: 8px;
    background: #f9f9f9;
}

.task-input__container {
    display: flex;
    flex-direction: column;

    width: 100%;
}

.task-input {
    margin-top: 12px;
    padding: 12px;

    border-radius: 4px;
    border: 1px solid var(--divider-color, #e4e4e4);
    background: var(--white, #fff);

    font-size: 15px;
    font-weight: 500;
}

.task-input:focus {
    border: 1px solid var(--primary-color);
}

.task-input::placeholder {
    font-family: 'Montserrat', sans-serif;
    color: var(--gray, #9f9f9f);
    font-size: 15px;
    font-weight: 500;
}

textarea {
    font-family: 'Montserrat', sans-serif;
    resize: none;
    overflow: hidden;
}

.title-description-img img {
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-image {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 40px;
    width: 40px;

    border-radius: 50%;
    background-color: var(--primary-light);

    color: var(--primary-color);
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
}

.task-profile {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;

    margin-bottom: 8px;
}

.task-profile-img {
    display: flex;
}

.task-profile img {
    width: 28px;
    height: 28px;
    margin-right: 8px;

    border-radius: 50%;
}

.task-profile svg {
    width: 28px;
    height: 28px;
    margin-right: 8px;
}

.task-profile p {
    display: flex;
    align-items: center;
}

.custom-checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

/* для элемента label, связанного с .custom-checkbox */
.custom-checkbox + label {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    user-select: none;

    width: 100%;

    color: var(--black, #333);
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
}

/* создание в label псевдоэлемента before со следующими стилями */
.custom-checkbox + label::before {
    content: '';
    display: inline-block;
    width: 22px;
    height: 22px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #adb5bd;
    background-color: #f6f6f6;
    border-radius: 0.25em;
    margin-left: 12px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
}

/* стили при наведении курсора на checkbox */
.custom-checkbox:not(:disabled):not(:checked) + label:hover::before {
    border-color: #b3d7ff;
}

/* стили для активного чекбокса (при нажатии на него) */
.custom-checkbox:not(:disabled):active + label::before {
    background-color: #b3d7ff;
    border-color: #b3d7ff;
}

/* стили для чекбокса, находящегося в фокусе */
.custom-checkbox:focus + label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
.custom-checkbox:focus:not(:checked) + label::before {
    border-color: #80bdff;
}

/* стили для чекбокса, находящегося в состоянии checked */
.custom-checkbox:checked + label::before {
    border-color: var(--primary-color);
    background-color: var(--primary-color);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.input-file {
    position: relative;
    display: inline-block;

    width: 100%;

    margin-top: 10px;
    margin-bottom: 10px;
}

.input-file svg {
    margin-right: 4px;
}

.input-file span {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    outline: none;
    text-decoration: none;
    text-align: center;
    box-sizing: border-box;
    margin: 0;
    border-radius: 6px;
    border: 1px dashed #9f9f9f;
    width: 100%;
    padding: 10px 28px;

    color: var(--primary-color);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}

.input-file input[type='file'] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    display: block;
    width: 0;
    height: 0;
}

.task-full--imgs {
    width: 100%;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 6px;
}

.task-full__downloads {
    width: 100%;
    height: 60px;

    object-fit: cover;

    border-radius: 6px;
}

.task-full__file {
    width: 100%;
    height: 60px;

    border-radius: 6px;
    background-color: #333;
    color: white;
}

.task-full__downloads--preloader {
    height: 60px;

    background-color: #444;
}

.task-file {
    padding: 8px 12px;

    display: flex;
    align-items: center;

    border-radius: 11px;
    border: 1px solid var(--divider-color, #e4e4e4);
}

.task-file:not(:last-child) {
    margin-bottom: 8px;
}

.task-file--title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    color: #333;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}

.task-file--image {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 4px;
    margin-right: 8px;

    background-color: #f6f6f6;
    border: 1px solid #e4e4e4;
    border-radius: 50%;
}

.title-description-note {
    display: flex;
    align-items: flex-start;

    padding: 10px 12px;

    border-radius: 6px;
    background: #f9f9f9;
}

.title-description-note svg {
    margin-right: 8px;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url('../../assets/img/TaskFull/arrow_down.svg') white no-repeat 98.5% !important;
}

select::-ms-expand {
    display: none;
}

.title-task__search {
    position: absolute;
    top: 7px;
    right: 0;
    cursor: pointer;
}
</style>
