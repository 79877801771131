<template>
    <div style="padding-bottom: 60px">
        <Preloader v-if="isLoading" />
        <slot>
            <div>
                <div class="message__background" v-if="selectedImage" @click="closeImageFullSize">
                    <svg
                        @click="closeImageFullSize"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M13 1L1 13"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M1 1L13 13"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>

                    <img @click.stop class="message__background--image" :src="selectedImage" alt="" />
                </div>
            </div>

            <h2 class="h2-primary header-date" v-if="timeElement">
                {{ timeElement.value ? getTime(timeElement.value) : 'Дата не установлена' }}
            </h2>
            <div class="task-full-wrapper" v-for="(element, id) in parentElement">
                <div class="primary-div" v-if="element.fields.length > 0" :key="id">
                    <div class="task-name" @click="element.isOpen = !element.isOpen">
                        <h2 class="h2-primary">{{ element.name }}</h2>
                        <button class="button-primary">
                            <img v-if="element.isOpen" src="@/assets/img/Arrow/hide-arrow.svg" />
                            <img v-else src="@/assets/img/Arrow/show-arrow.svg" />
                        </button>
                    </div>

                    <div v-if="element.isOpen" class="primary-div__desc">
                        <slot v-for="(field, id) in element.fields">
                            <slot
                                v-if="
                                    field.value || field.type === checkmark || field.type === file || field.isEditable
                                "
                            >
                                <FieldItem
                                    @openImageFullSize="openImageFullSize"
                                    :key="id"
                                    :isTaskClosed="task.closed"
                                    :id="field.id"
                                    :isEditable="field.isEditable"
                                    :editPermission="editPermission"
                                    :taskId="task.id"
                                    :title="field.name"
                                    :catalogs_id="field.catalogs_id"
                                    :inputType="field.inputType"
                                    :constName="field.constName"
                                    :type="field.type"
                                    :element="field.value"
                                    :isVisible="field.isVisible"
                                    :rerender="rerender"
                                />
                            </slot>
                        </slot>
                    </div>
                </div>
            </div>

            <div class="task-full-wrapper" v-if="!isTaskClosed && taskStatus">
                <div class="primary-div status-div gap">
                    <div>
                        <div class="button-div">
                            <div>
                                <p class="title-task-description" style="margin-bottom: 2px">Статус задачи</p>
                                <h3 class="h3-primary">Принять задачу</h3>
                            </div>

                            <div
                                class="switch-btn"
                                :class="{ 'switch-on': taskStatus.accepted, 'switch-btn-disabled': checkboxDisabled }"
                                @click="switchStatus"
                            ></div>
                        </div>
                        <p v-if="errorStatus" class="title-task-description" style="color: rgba(255, 0, 0, 0.52)">
                            Ошибка при отправке статуса
                        </p>
                    </div>

                    <div class="button-div" v-if="isTaskAccepted">
                        <div class="user_profile">
                            <slot v-if="!acceptedUserProfile.avatar_url">
                                <img
                                    class="user_profile-avatar mention_avatar"
                                    src="../assets/img/Avatar/anon.png"
                                    alt=""
                                />
                            </slot>

                            <slot v-else>
                                <img
                                    class="user_profile-avatar"
                                    :src="url + acceptedUserProfile.avatar_url + '?is_preview=1&avatar_size=64'"
                                    alt=""
                                />
                            </slot>

                            <div class="user_profile-name h3-primary">
                                {{ acceptedUserProfile.info.name + ' ' + (acceptedUserProfile.info.surname || '') }}
                            </div>
                        </div>
                    </div>

                    <div class="button-div" v-if="acceptByOtherFitter && isTaskAccepted">
                        <p class="h3-primary">
                            Задача была принята другим пользователем, но вы можете принять её на себя
                        </p>
                    </div>

                    <div class="button-div" v-if="acceptByOtherFitter && isTaskAccepted">
                        <button @click="acceptTask" class="form-button">Принять на себя</button>
                    </div>
                </div>
            </div>
            <div class="task-full-wrapper" v-if="taskStatus" v-show="timerIsShow || isTimerRunning">
                <div class="primary-div">
                    <div class="time-tracking">
                        <h3 class="h3-primary">Учет времени</h3>
                        <Timer ref="timerRef" :isRunning="isRunningTimerState" v-if="shouldShowTimer" />
                        <div class="timer__container" v-else>
                            <svg
                                width="16"
                                height="17"
                                viewBox="0 0 16 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M3.43359 1.83333V15.1667"
                                    stroke="#9F9F9F"
                                    stroke-width="1.5"
                                    stroke-miterlimit="10"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M3.43359 3.16667H10.9003C12.7003 3.16667 13.1003 4.16667 11.8336 5.43334L11.0336 6.23334C10.5003 6.76667 10.5003 7.63334 11.0336 8.1L11.8336 8.90001C13.1003 10.1667 12.6336 11.1667 10.9003 11.1667H3.43359"
                                    stroke="#9F9F9F"
                                    stroke-width="1.5"
                                    stroke-miterlimit="10"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            <div class="timer">
                                {{ formatTime() }}
                            </div>
                        </div>
                        <div
                            v-if="showStartTimerButton && timerIsShow"
                            v-show="isRunningTimerState || timerIsShow"
                            style="width: 100%"
                        >
                            <slot>
                                <button class="timer-button" @click="toggleTimerPopover(true)">
                                    <svg
                                        width="25"
                                        height="24"
                                        viewBox="0 0 25 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6.5 7.30896V15.9558C6.5 17.7268 8.42452 18.8381 9.96054 17.9526L13.7102 15.7932L17.4599 13.6247C18.9959 12.7392 18.9959 10.5255 17.4599 9.64007L13.7102 7.47159L9.96054 5.31215C8.42452 4.42668 6.5 5.52899 6.5 7.30896Z"
                                            fill="white"
                                            stroke="white"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                    Начать учет времени
                                </button>
                            </slot>
                        </div>
                        <div
                            v-else-if="showFinishTimerButton && timerIsShow"
                            v-show="isRunningTimerState || timerIsShow"
                            style="width: 100%"
                        >
                            <slot>
                                <button class="timer-button timer-button--finish" @click="toggleTimerPopover(true)">
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M5.1499 2V22"
                                            stroke="white"
                                            stroke-width="1.5"
                                            stroke-miterlimit="10"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            d="M5.1499 4H16.3499C19.0499 4 19.6499 5.5 17.7499 7.4L16.5499 8.6C15.7499 9.4 15.7499 10.7 16.5499 11.4L17.7499 12.6C19.6499 14.5 18.9499 16 16.3499 16H5.1499"
                                            fill="white"
                                        />
                                    </svg>
                                    Завершить учет времени
                                </button>
                            </slot>
                        </div>
                        <div v-else-if="showClearTimerButton" v-show="finishTime" style="width: 100%">
                            <slot>
                                <button class="timer-button timer-button--clear">
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M5.15039 2V22"
                                            stroke="#009BF1"
                                            stroke-width="1.5"
                                            stroke-miterlimit="10"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            d="M5.15039 4H16.3504C19.0504 4 19.6504 5.5 17.7504 7.4L16.5504 8.6C15.7504 9.4 15.7504 10.7 16.5504 11.4L17.7504 12.6C19.6504 14.5 18.9504 16 16.3504 16H5.15039"
                                            fill="#009BF1"
                                        />
                                    </svg>
                                    Установка завершена за {{ formatTime() }}
                                </button>
                            </slot>
                        </div>
                    </div>
                </div>
                <Popup :active="isTimerPopoverOpen" @setActive="toggleTimerPopover" :fit-content="true">
                    <div v-if="showStartTimerButton">
                        <div class="popup_header">
                            <h2 class="popup_header__title popover_item" style="text-align: left">
                                Запустить учет времени?
                            </h2>
                            <div style="text-align: right" class="" @click="toggleTimerPopover(false)">
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle cx="12" cy="12" r="12" fill="#F6F6F6" />
                                    <path
                                        d="M7.625 16.7639L16.7639 7.625"
                                        stroke="#999999"
                                        stroke-width="1.33657"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M16.7639 16.7639L7.625 7.625"
                                        stroke="#999999"
                                        stroke-width="1.33657"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </div>
                        </div>
                        <div class="popup_content--timer">
                            <p class="popup_text__timer">
                                После запуска его нельзя будет поставить на паузу или сбросить
                            </p>
                        </div>
                        <div class="popup_content--timer">
                            <button
                                class="timer-button popover_timer-button"
                                @click="startTaskTimer"
                                :disabled="taskButtonIsDisabled"
                            >
                                <svg
                                    width="25"
                                    height="24"
                                    viewBox="0 0 25 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M6.5 7.30896V15.9558C6.5 17.7268 8.42452 18.8381 9.96054 17.9526L13.7102 15.7932L17.4599 13.6247C18.9959 12.7392 18.9959 10.5255 17.4599 9.64007L13.7102 7.47159L9.96054 5.31215C8.42452 4.42668 6.5 5.52899 6.5 7.30896Z"
                                        fill="white"
                                        stroke="white"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                                Начать учет
                            </button>
                            <button
                                class="timer-button popover_timer-button popover_timer-button--cancel"
                                @click="toggleTimerPopover(false)"
                            >
                                Отмена
                            </button>
                        </div>
                    </div>
                    <div v-else>
                        <div class="popup_header">
                            <h2 class="popup_header__title popover_item" style="text-align: left">
                                Завершить учет времени?
                            </h2>
                            <div style="text-align: right" class="" @click="toggleTimerPopover(false)">
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle cx="12" cy="12" r="12" fill="#F6F6F6" />
                                    <path
                                        d="M7.625 16.7639L16.7639 7.625"
                                        stroke="#999999"
                                        stroke-width="1.33657"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M16.7639 16.7639L7.625 7.625"
                                        stroke="#999999"
                                        stroke-width="1.33657"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </div>
                        </div>
                        <div class="popup_content--timer">
                            <p class="popup_text__timer">После завершения его нельзя будет запустить заново</p>
                        </div>
                        <div class="popup_content--timer">
                            <button
                                class="timer-button timer-button--finish popover_timer-button"
                                @click="stopTaskTimer"
                                :disabled="taskButtonIsDisabled"
                            >
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M5.1499 2V22"
                                        stroke="white"
                                        stroke-width="1.5"
                                        stroke-miterlimit="10"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M5.1499 4H16.3499C19.0499 4 19.6499 5.5 17.7499 7.4L16.5499 8.6C15.7499 9.4 15.7499 10.7 16.5499 11.4L17.7499 12.6C19.6499 14.5 18.9499 16 16.3499 16H5.1499"
                                        fill="white"
                                    />
                                </svg>
                                Завершить учет
                            </button>
                            <button
                                class="timer-button popover_timer-button popover_timer-button--cancel"
                                @click="toggleTimerPopover(false)"
                            >
                                Отмена
                            </button>
                        </div>
                    </div>
                </Popup>
            </div>
        </slot>
    </div>
</template>

<script>
import FieldItem from '@/components/field/FieldItem';
import Preloader from '@/shared/ui/preloader';
import Timer from '@/components/timer/ui/Timer';
import { COMMENT_TYPE, CONSTANT_Arr_Months } from '@/constants/constants';
import { deleteTaskTimer, getTaskTimer, saveTimersList } from '@/utils/storage/storage-task-timer-method';
import Popup from '@/shared/ui/popup';
import { TaskListMethodMixin } from '@/mixin/TaskListMethodMixin';
import { TaskListSelectMixin } from '@/mixin/TaskListSelectMixin';
import { STORAGE_KEY } from '@/utils/const';

export default {
    name: 'TaskForm',
    components: {
        FieldItem,
        Preloader,
        Timer,
        Popup,
    },
    props: {
        task: {
            type: Object,
            required: true,
        },
        catalogId: {
            type: Array,
        },
        parentElement: {
            type: Array,
        },
        timeElement: {
            type: Object,
        },
        isLoading: {
            type: Boolean,
        },
        isPzForm: {
            type: Boolean,
        },
        login: {
            type: Object,
        },
        isTaskClosed: {
            type: Boolean,
        },
        rerender: {
            type: Boolean,
        },
        editPermission: {
            type: Boolean,
        },
        taskStatusAccept: {
            type: Object,
        },
        taskStatusAcceptError: {
            type: Boolean,
        },
    },
    mixins: [TaskListSelectMixin, TaskListMethodMixin],
    data() {
        return {
            errorStatus: false,
            fields: [],
            isStatusActive: false,
            checkmark: COMMENT_TYPE.checkmark,
            file: COMMENT_TYPE.file,
            selectedImage: '',
            isRender: false,
            url: `${process.env.VUE_APP_TASK_RADAR}`,
            prevAcceptedUser: null,
            popoverActive: false,
            currentTaskId: null,
            taskButtonIsDisabled: false,
        };
    },
    created() {
        this.scrollToTop();
    },
    mounted() {
        this.$store.dispatch('changeActiveTab', 'task');
        this.$emit('changeHeader', 'Задача');
        // this.$store.dispatch('setTimerState', false);
        // this.$store.dispatch('setTimerValue', 0);
        this.currentTaskId = this.$route.params.id;
    },
    computed: {
        taskStatus() {
            return this.taskStatusAccept;
        },
        myProfile() {
            return this.$store.getters.getUserData;
        },
        acceptedUserProfile() {
            const allUsers = this.$store.getters.getAllUsers;
            return allUsers.find((user) => user.id.toString() === this.taskStatus.accepted_user.toString());
        },
        acceptByOtherFitter() {
            return this.taskStatus.accepted_user !== this.myProfile.id;
        },
        checkboxDisabled() {
            return this.acceptByOtherFitter && this.taskStatus.accepted;
        },
        isTaskAccepted() {
            return this.taskStatus.accepted;
        },
        canCurrentUserSeeTimer() {
            return this.taskStatus.accepted_user === this.myProfile.id || !this.taskStatus.accepted;
        },
        timerIsShow() {
            this.$store.dispatch('setTimerHeaderShow', this.canCurrentUserSeeTimer && this.taskStatus.accepted);
            return this.canCurrentUserSeeTimer && this.taskStatus.accepted;
        },
        isTimerStart() {
            return this.$store.getters.getIsTimerStart;
        },
        isTimerFinish() {
            return this.$store.getters.getIsTimerFinish;
        },
        timerValue() {
            if (this.taskStatusAccept && this.taskStatusAccept.finish && this.taskStatusAccept.start) {
                this.$store.dispatch('setIsTimerRunning', false);
                deleteTaskTimer(this.currentTaskId);
                return this.taskStatusAccept.finish - this.taskStatusAccept.start;
            } else if (this.taskStatusAccept && !this.taskStatusAccept.finish && this.taskStatusAccept.start) {
                this.$store.dispatch('setIsTimerRunning', true);
                saveTimersList(this.currentTaskId, STORAGE_KEY.timer_value, this.taskStatusAccept.start);
            } else if (
                this.taskStatusAccept &&
                this.taskStatusAccept.finish === null &&
                this.taskStatusAccept.start === null
            ) {
                this.$store.dispatch('setIsTimerRunning', false);
                deleteTaskTimer(this.currentTaskId);
            } else {
                this.$store.dispatch('setIsTimerRunning', false);
                return null;
            }
        },
        currentTimerValue() {
            return getTaskTimer(this.$route.params.id);
        },
        finishTime() {
            return this.$store.getters.getFinishTimeValue;
        },
        isTimerRunning() {
            return this.$store.getters.getIsTimerRunning;
        },
        // Новые свойства
        isRunningTimerState() {
            return (
                (!this.isTimerFinish &&
                    ((this.currentTimerValue && !this.isTimerStart) ||
                        (!this.currentTimerValue && this.isTimerStart))) ||
                this.isTimerRunning
            );
        },
        shouldShowTimer() {
            return !this.timerValue && !this.isTimerFinish;
        },
        showStartTimerButton() {
            return (
                !this.isTimerStart &&
                !this.isTimerFinish &&
                !this.timerValue &&
                !this.currentTimerValue &&
                !this.isTimerRunning
            );
        },
        showFinishTimerButton() {
            return this.isRunningTimerState;
        },
        showClearTimerButton() {
            return !this.isRunningTimerState && !this.showStartTimerButton;
        },
        isTimerPopoverOpen() {
            return this.$store.getters.getTimerPopoverOpen;
        },
    },
    watch: {
        taskStatusAcceptError(newValue) {
            if (newValue && this.prevAcceptedUser && this.prevAcceptedUser.accepted_user) {
                this.taskStatus.accepted = this.prevAcceptedUser.accepted;
                this.taskStatus.accepted_user = this.prevAcceptedUser.accepted_user;
                this.errorStatus = true;

                setTimeout(() => {
                    this.errorStatus = false;
                }, 4000);
            }
        },
    },
    methods: {
        toggleTimerPopover(status) {
            this.$store.dispatch('setTimerPopoverOpen', status);
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        openImageFullSize(url) {
            this.selectedImage = url;
            document.documentElement.style.overflow = 'hidden';
        },
        closeImageFullSize() {
            this.selectedImage = '';
            document.documentElement.style.overflow = 'scroll';
        },
        getTime(time) {
            let d = new Date(time * 1000),
                month = d.getMonth(),
                day = d.getDate(),
                hour = d.getHours(),
                minutes = ('0' + d.getMinutes()).slice(-2);

            return `${hour}:${minutes} ${day} ${CONSTANT_Arr_Months[month]}`;
        },
        updateTaskStatus(accepted) {
            if (this.taskStatus && this.taskStatus.hasOwnProperty('accepted')) {
                const data = {
                    accepted: accepted,
                    accepted_user: Number(this.$store.getters.getCurrentUserId),
                };

                this.prevAcceptedUser = {
                    accepted: this.taskStatus.accepted,
                    accepted_user: this.taskStatus.accepted_user,
                };

                this.$emit('updateTaskStatusHandler', data);

                this.taskStatus.accepted = accepted;
                this.taskStatus.accepted_user = this.myProfile.id;
            }
        },
        async switchStatus() {
            if (this.checkboxDisabled) return;

            const newAcceptedStatus = !this.taskStatus.accepted;
            this.updateTaskStatus(newAcceptedStatus);
        },

        acceptTask() {
            this.updateTaskStatus(this.taskStatus.accepted);
        },

        async startTaskTimer() {
            this.taskButtonIsDisabled = true; // Дизейбл кнопки на время запроса
            try {
                await this.$refs.timerRef.getStartTimestamp();
                this.toggleTimerPopover(false);
            } catch (error) {
                console.log(error);
            } finally {
                this.taskButtonIsDisabled = false;
            }
        },
        async stopTaskTimer() {
            this.taskButtonIsDisabled = true; // Дизейбл кнопки на время запроса
            try {
                await this.$refs.timerRef.getFinishTimestamp();
                this.toggleTimerPopover(false);
            } catch (error) {
                console.log(error);
            } finally {
                this.taskButtonIsDisabled = false;
            }
        },
        formatTime() {
            if (this.timerValue) {
                this.$store.dispatch('setTimerValue', this.timerValue);
                const minutesPassed = Math.floor(new Date(this.timerValue).getTime() / 60);
                const hours = Math.floor(minutesPassed / 60);
                const minutes = minutesPassed % 60;
                return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
            }
            const hours = Math.floor(this.finishTime / 60);
            const minutes = this.finishTime % 60;
            return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
        },
    },
};
</script>

<style scoped>
.header-date {
    padding: 28px 0 16px 20px;

    font-size: 20px;
}

.gap {
    display: flex;
    flex-direction: column;

    gap: 12px;
}

.switch-btn {
    display: inline-block;
    width: 40px;
    height: 24px;
    border-radius: 19px;
    background: #e2e2e2;
    z-index: 0;
    margin: 0;
    padding: 0;
    border: none;
    position: relative;
    transition-duration: 300ms;
}

.switch-btn::after {
    content: '';
    height: 16px;
    width: 16px;
    border-radius: 17px;
    background: #fff;
    top: 4px;
    left: 5px;
    transition-duration: 300ms;
    position: absolute;
    z-index: 1;
}

.switch-on {
    background: #00bb34;
}

.switch-btn-disabled {
    opacity: 40%;
}

.switch-on::after {
    left: 21px;
}

.status-div {
    display: flex;
}

.status-text {
    color: #999999;
    font-size: 15px;
    line-height: 18px;
}

.primary-div__desc-pz:not(:last-child) {
    padding-bottom: 12px;
    border-bottom: 1px solid var(--divider-color);
}

.button-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.button-header {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #333;
}

.mb-15 {
    margin-bottom: 15px;
}

.not-found-wrapper {
    padding-top: 30px;
    display: flex;
    width: 300px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 50px auto;
}

.not-found-wrapper p {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #333;
    text-align: center;
}

.not-found-wrapper button {
    background: #009bf1;
    border-radius: 4px;
    width: 280px;
    padding: 12px 50px;
    border: none;
    color: white;
}

.not-found__router {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
}

.message__background {
    width: 100%;
    height: 100%;

    position: fixed;
    left: 0;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0 20px;

    background-color: rgba(0, 0, 0, 0.75);

    z-index: 1000;
}

.message__background img {
    width: 100%;
    height: auto;
}

.message__background svg {
    position: absolute;
    top: 60px;
    right: 20px;

    width: 20px;
    height: 20px;
}

.user_profile {
    width: 100%;

    display: flex;
    align-items: center;

    gap: 12px;
    padding: 8px 12px;

    border-radius: 6px;
    background-color: var(--gray-substrate);
}

.user_profile-avatar {
    width: 32px;
    height: 32px;

    border-radius: 50%;
}

.user_profile-name {
}

.timer-button {
    border: none;
    color: #ffffff;
    background: #009bf1;
    border-radius: 4px;
    font-weight: 600;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 8px 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
}

.timer-button:disabled {
    opacity: 0.35;
}

.timer-button--finish {
    background: #00bb34;
}

.timer-button--clear {
    background: rgba(0, 155, 241, 0.10999999940395355);
    color: #009bf1;
}

.popover_timer-button {
    padding: 12px 0;
}

.popover_timer-button--cancel {
    color: #333333;
    background: #ffffff;
    width: 30%;
}

.time-tracking {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
}

.timer__container {
    display: flex;
    gap: 4px;
    background: #f6f6f6;
    padding: 4px 12px;
    border-radius: 6px;
}

.timer {
    color: #333333;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
}

.popup_content--timer {
    display: flex;
    text-align: left;
    gap: 12px;
}

.popup_content--timer:not(:last-child) {
    margin-bottom: 20px;
}

.popup_text__timer {
    font-weight: 500;
    font-size: 15px;
    line-height: 1.3;
}
</style>
