import HttpClient from "@/app/config/axiosConfig/axiosConfig";

export const login_radar = (userAccount:  {username: string, password: string}): Promise<{data: {token: string, user_id: string} }| boolean> => {

	return HttpClient({
		method: 'POST',
		url: `/users/login`,
		data: userAccount,
		withCredentials: true
	})
		.then((resp) => {
			if (resp.status === 200) {
				return resp.data
			} else {
				console.error('Не удалось войти в кабинет. Код ошибки ', resp.status)
			}
		})
		.catch(() => {
			return false
		})
}
