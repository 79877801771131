import axios from 'axios';
import * as Sentry from '@sentry/vue';

// Настраиваем общий экземпляр Axios
const HttpClient = axios.create({
    baseURL: process.env.VUE_APP_TASK_RADAR, // Ваш базовый URL
});

// Перехватчик запросов
HttpClient.interceptors.request.use((config) => {
    Sentry.addBreadcrumb({
        category: 'http',
        message: `Request to ${config.url}`,
        data: {
            method: config.method,
            url: config.url,
            headers: config.headers,
            body: config.data, // Тело запроса
        },
        level: 'info',
    });
    return config;
});

// Перехватчик ответов
HttpClient.interceptors.response.use(
    (response) => {
        Sentry.addBreadcrumb({
            category: 'http',
            message: `Response from ${response.config.url}`,
            data: {
                status: response.status,
                headers: response.headers,
                body: response.data, // Тело ответа
            },
            level: 'info',
        });
        return response;
    },
    (error) => {
        // Логируем ошибку в Sentry
        Sentry.captureException(error, {
            extra: {
                url: error.config?.url,
                method: error.config?.method,
                data: error.config?.data,
                response: error.response?.data,
            },
        });
        return Promise.reject(error);
    }
);

export default HttpClient;
