
import Slide from '@/components/Burger.vue';
import { defineComponent } from 'vue';
import { howLongAgo } from '@/utils/time';
import { callNotUpdateNotification } from '@/utils/callNotUpdateNotification';
import { STORAGE_KEY } from '@/utils/const';
import { Route } from 'vue-router';
import Timer from '@/components/timer/ui/Timer.vue';
import { getTaskTimer } from '@/utils/storage/storage-task-timer-method';

export default defineComponent({
    name: 'Header',
    components: { Slide, Timer },
    props: {
        logout: {
            type: Function,
        },
        cRout: {
            type: String,
        },
        arrowBack: {
            type: Boolean,
        },
        goBack: {
            type: Function,
        },
        slideLoaded: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            headerIsLoaded: false,
            userData: false,
        };
    },
    mounted() {
        this.headerIsLoaded = true;

        if (!this.$store.getters.getTaskSelected) {
            this.$store.dispatch('addTaskSelected', this.$route.params.id);
        }

        if (this.$route.name === 'Home') {
            const time = this.updateLastSync(this.$store.getters.getLastSyncTaskList);
            this.$store.dispatch('changeLastSync', time);
        } else {
            if (this.$store.getters.getActiveTab === 'chat') {
                const time = this.updateLastSync(this.$store.getters.getLastSyncComments);
                this.$store.dispatch('changeLastSync', time);
            }

            if (this.$store.getters.getActiveTab === 'task') {
                const time = this.updateLastSync(this.$store.getters.getLastSyncTask);
                this.$store.dispatch('changeLastSync', time);
            }

            if (this.$store.getters.getActiveTab === 'tracker') {
                this.$store.dispatch('changeLastSync', 0);
            }
        }
    },
    computed: {
        renderBurger(): any {
            return this.headerIsLoaded && this.$store.getters.getUserData;
        },
        renderSlide(): boolean {
            return this.slideLoaded;
        },
        timerValue(): number {
            return this.$store.getters.getTimerValue;
        },
        isTimerFinish(): boolean {
            return this.$store.getters.getIsTimerFinish;
        },
        isTimerStart(): boolean {
            return this.$store.getters.getIsTimerStart;
        },
        currentTimerValue(): number {
            return getTaskTimer(this.$route.params.id) as number;
        },
        isTimerRunning(): boolean {
            return this.$store.getters.getIsTimerRunning;
        },
        isTimerHeaderShow(): boolean {
            return this.$store.getters.getTimerHeaderShow;
        },
        shouldShowTimer(): boolean {
            return !this.timerValue && !this.isTimerFinish;
        },
        isRunningTimerState(): boolean {
            return (
                (!this.isTimerFinish &&
                    ((this.currentTimerValue && !this.isTimerStart) ||
                        (!this.currentTimerValue && this.isTimerStart))) ||
                this.isTimerRunning
            );
        },
        finishTime(): number {
            return this.$store.getters.getFinishTimeValue;
        },
    },
    watch: {
        $route: async function (to: Route, from: Route) {
            if (to.name === 'TaskFull') {
                const time = this.updateLastSync(this.$store.getters.getLastSyncTask);
                await this.$store.dispatch('changeLastSync', time);

                await this.$store.dispatch('changeLastUpdateTaskList', null);
                await this.$store.dispatch('changeTaskNotUpdated', false);
            }

            if (to.name === 'Home') {
                const time = this.updateLastSync(this.$store.getters.getLastSyncTaskList);
                await this.$store.dispatch('changeLastSync', time);

                await this.$store.dispatch('changeLastUpdateTask', null);
                await this.$store.dispatch('changeLastUpdateComments', null);

                await this.$store.dispatch('changeTaskNotUpdated', false);
            }
        },
        '$store.getters.getLastSyncTask'() {
            if (this.$store.getters.getActiveTab === 'task') {
                const time = this.updateLastSync(this.$store.getters.getLastSyncTask);
                this.$store.dispatch('changeLastSync', time);
            }
        },
        '$store.getters.getLastSyncTaskList'() {
            const time = this.updateLastSync(this.$store.getters.getLastSyncTaskList);
            this.$store.dispatch('changeLastSync', time);
        },
        '$store.getters.getLastSyncComments'() {
            if (this.$store.getters.getActiveTab === 'chat') {
                const time = this.updateLastSync(this.$store.getters.getLastSyncComments);
                this.$store.dispatch('changeLastSync', time);
            }
        },
        '$store.getters.getActiveTab'(newValue: 'chat' | 'task' | 'tracker') {
            if (newValue === 'chat') {
                if (this.$store.getters.getLastSyncComments) {
                    callNotUpdateNotification(STORAGE_KEY.comments, this.$store.getters.getTaskSelected);
                }

                const time = this.updateLastSync(this.$store.getters.getLastSyncComments);
                this.$store.dispatch('changeLastSync', time);
            }

            if (newValue === 'task') {
                const time = this.updateLastSync(this.$store.getters.getLastSyncTask);
                this.$store.dispatch('changeLastSync', time);
            }

            if (newValue === 'tracker') {
                this.$store.dispatch('changeLastSync', 0);
            }
        },
    },
    methods: {
        updateLastSync(value: number | null) {
            if (!value) return null;

            return howLongAgo(value);
        },
        async backToTimer() {
            if (this.isTimerStart) {
            }
            await this.$store.dispatch('changeActiveTab', 'task'); // Ждем смены таба
            window.scrollTo({
                top: document.body.scrollHeight,
                behavior: 'smooth',
            });
            await this.$store.dispatch('setTimerPopoverOpen', true);
        },
        formatTime() {
            if (this.timerValue) {
                const minutesPassed = Math.floor(new Date(this.timerValue).getTime() / 60);
                const hours = Math.floor(minutesPassed / 60);
                const minutes = minutesPassed % 60;
                return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
            }
            const hours = Math.floor(this.finishTime / 60);
            const minutes = this.finishTime % 60;
            return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
        },
    },
});
