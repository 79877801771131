<template>
    <main class="wrapper" :style="selectTask ? 'margin: 114px auto' : 'margin: 75px auto;'">
        <Preloader v-if="isTasksLoading && !isFoundTasksEmpty" />

        <div class="task_watch_wrapper" v-if="selectTask">
            <div class="task_watch-title" @click="selectAll">Выбрать все</div>
            <div class="task_watch-title" @click="cancelSelect">Отмена</div>
        </div>

        <slot v-if="!isTasksLoading">
            <div class="task-input__wrapper">
                <form class="box" @submit.prevent="applyFilters">
                    <slot v-if="deepSearchString">
                        <svg
                            @click="clearSearchStringAndGoBack"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M4 14L14 4"
                                stroke="#009BF1"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M14 14L4 4"
                                stroke="#009BF1"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </slot>
                    <input
                        inputmode="search"
                        @blur="applyFilters"
                        v-model="deepSearchString"
                        type="text"
                        placeholder="Поиск по задачам"
                    />
                    <svg
                        @click="applyFilters"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                            stroke="#999999"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M22 22L18.5 18.5"
                            stroke="#999999"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </form>
            </div>

            <div class="task-filter">
                <div class="task-filter__set" @click="popoverActive = true">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1.5 14.25L9.75 14.25"
                            stroke="#999999"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M12.75 14.25L16.5 14.25"
                            stroke="#999999"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M1.5 9L5.25 9"
                            stroke="#999999"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M8.25 9L16.5 9"
                            stroke="#999999"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M1.5 3.75L9.75 3.75"
                            stroke="#999999"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M12.75 3.75L16.5 3.75"
                            stroke="#999999"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M9.75 2.25L9.75 5.25"
                            stroke="#999999"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M9.75 12.75L9.75 15.75"
                            stroke="#999999"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M8.25 7.5L8.25 10.5"
                            stroke="#999999"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <div class="task-filter__filter">
                        Фильтры
                        <div v-if="filters_count" class="task-filter__count">
                            <span>{{ filters_count }}</span>
                        </div>
                    </div>
                </div>
                <slot v-if="date_end || date_start || form_id.length > 0 || is_closed !== null || deepSearchString">
                    <div class="task-filter__reset" @click="resetFilters">Очистить фильтры</div>
                </slot>
            </div>
        </slot>

        <slot v-if="isFoundTasksEmpty && !isTasksLoading">
            <NotFoundError title="Задачи не найдены" description="К сожалению, по вашему запросу ничего не найдено" />
        </slot>

        <div class="found_task" v-if="!isFoundTasksEmpty && !isTasksLoading">
            <h2>Найдено {{ tasksCount }} {{ taskPlural }}</h2>

            <div v-for="(value, name) in dataFormated" :key="name" style="margin-bottom: 12px">
                <div class="task-list-item task-list-item-filter">
                    <Task
                        v-for="taskMin in value.tasks"
                        :key="taskMin.id"
                        :id="taskMin.id"
                        ref="foo"
                        :showYear="true"
                        :selectTask="selectTask"
                        :task-min="taskMin"
                        :selectedIdArr="selectedIdArr"
                        @go-to-full-task="goToFullTask"
                        @setSelectActive="setSelectActive"
                        @addIdInArrForRead="addIdInArrForRead"
                    />
                </div>
            </div>
        </div>

        <Popup :active="popoverActive" @setActive="changePopoverStatus">
            <div class="popup_header">
                <button class="popup_header__reset popover_item" style="text-align: left" @click="resetFilters">
                    Очистить
                </button>
                <h2 class="popup_header__title popover_item" style="text-align: center">Фильтры</h2>
                <div style="text-align: right" class="popover_item" @click="changePopoverStatus(false)">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12" cy="12" r="12" fill="#F6F6F6" />
                        <path
                            d="M7.625 16.7639L16.7639 7.625"
                            stroke="#999999"
                            stroke-width="1.33657"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M16.7639 16.7639L7.625 7.625"
                            stroke="#999999"
                            stroke-width="1.33657"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </div>
            </div>
            <div class="popup_content">
                <form class="box popup_box" @submit.prevent="applyFilters">
                    <slot v-if="deepSearchString">
                        <svg
                            @click="deepSearchString = ''"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M4 14L14 4"
                                stroke="#009BF1"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M14 14L4 4"
                                stroke="#009BF1"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </slot>
                    <input
                        inputmode="search"
                        @blur="applyFilters"
                        v-model="deepSearchString"
                        type="text"
                        placeholder="Поиск по задачам"
                    />
                    <svg
                        @click="applyFilters"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                            stroke="#999999"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M22 22L18.5 18.5"
                            stroke="#999999"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </form>
            </div>

            <div class="popup_content">
                <div class="popup_select">
                    <div class="popup_select__text">Дата</div>
                    <div class="popup_select__flex">
                        <slot v-for="date_preset_item in date_preset_arr">
                            <div
                                :class="date_preset_item === date_preset ? 'popup_select__timer-active' : null"
                                @click="selectActiveTime(date_preset_item)"
                                class="popup_select__timer"
                            >
                                {{ date_preset_item }}
                            </div>
                        </slot>
                    </div>
                </div>
                <div class="popup_calendar">
                    <v-date-picker v-model="date_start" mode="date" style="position: relative">
                        <template v-slot="{ inputValue, inputEvents }">
                            <input
                                placeholder="От"
                                class="popup_calendar--item"
                                :value="inputValue"
                                v-on="inputEvents"
                            />
                            <svg
                                class="popup_calendar--item-svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M6 1.5V3.75"
                                    stroke="#9F9F9F"
                                    stroke-width="1.25"
                                    stroke-miterlimit="10"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M12 1.5V3.75"
                                    stroke="#9F9F9F"
                                    stroke-width="1.25"
                                    stroke-miterlimit="10"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M2.625 6.8175H15.375"
                                    stroke="#9F9F9F"
                                    stroke-width="1.25"
                                    stroke-miterlimit="10"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M15.75 6.375V12.75C15.75 15 14.625 16.5 12 16.5H6C3.375 16.5 2.25 15 2.25 12.75V6.375C2.25 4.125 3.375 2.625 6 2.625H12C14.625 2.625 15.75 4.125 15.75 6.375Z"
                                    stroke="#9F9F9F"
                                    stroke-width="1.25"
                                    stroke-miterlimit="10"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M8.99661 10.275H9.00335"
                                    stroke="#9F9F9F"
                                    stroke-width="1.66667"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M6.22073 10.275H6.22747"
                                    stroke="#9F9F9F"
                                    stroke-width="1.66667"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M6.22073 12.525H6.22747"
                                    stroke="#9F9F9F"
                                    stroke-width="1.66667"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </template>
                    </v-date-picker>

                    <v-date-picker v-model="date_end" mode="date" style="position: relative">
                        <template v-slot="{ inputValue, inputEvents }">
                            <input
                                placeholder="До"
                                class="popup_calendar--item popup_calendar--item-second"
                                :value="inputValue"
                                v-on="inputEvents"
                            />
                            <svg
                                class="popup_calendar--item-svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M6 1.5V3.75"
                                    stroke="#9F9F9F"
                                    stroke-width="1.25"
                                    stroke-miterlimit="10"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M12 1.5V3.75"
                                    stroke="#9F9F9F"
                                    stroke-width="1.25"
                                    stroke-miterlimit="10"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M2.625 6.8175H15.375"
                                    stroke="#9F9F9F"
                                    stroke-width="1.25"
                                    stroke-miterlimit="10"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M15.75 6.375V12.75C15.75 15 14.625 16.5 12 16.5H6C3.375 16.5 2.25 15 2.25 12.75V6.375C2.25 4.125 3.375 2.625 6 2.625H12C14.625 2.625 15.75 4.125 15.75 6.375Z"
                                    stroke="#9F9F9F"
                                    stroke-width="1.25"
                                    stroke-miterlimit="10"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M8.99661 10.275H9.00335"
                                    stroke="#9F9F9F"
                                    stroke-width="1.66667"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M6.22073 10.275H6.22747"
                                    stroke="#9F9F9F"
                                    stroke-width="1.66667"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M6.22073 12.525H6.22747"
                                    stroke="#9F9F9F"
                                    stroke-width="1.66667"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </template>
                    </v-date-picker>
                </div>
                <button
                    class="popup_header__reset"
                    style="margin-top: 4px; padding-left: 0 !important"
                    v-if="date_start || date_end || date_preset"
                    @click="resetDate"
                >
                    Очистить дату
                </button>
            </div>

            <div class="popup_content">
                <div class="popup_select">
                    <div class="popup_select__text">Форма</div>
                    <select multiple name="select" class="task-input" v-model="form_id">
                        <slot v-for="form in allForms">
                            <option v-bind:value="form.id">{{ form.name }}</option>
                        </slot>
                    </select>
                </div>
            </div>

            <div class="popup_content">
                <div class="popup_select">
                    <div class="popup_select__text">Завершенные</div>
                    <select name="select" class="task-input" v-model="is_closed">
                        <option v-bind:value="null">Все</option>
                        <option v-bind:value="1">Только завершенные</option>
                        <option v-bind:value="0">Без завершенных</option>
                    </select>
                </div>
            </div>

            <div class="popup__last-child">
                <div class="popup_checkbox">
                    <div class="checkbox">
                        <input
                            class="custom-checkbox"
                            type="checkbox"
                            id="only_my"
                            name="only_my"
                            v-model="my_task_bool"
                        />
                        <label for="only_my">Показать только мои задачи</label>
                    </div>
                </div>
            </div>

            <button @click="applyFilters" class="popup_accept">Применить</button>
        </Popup>

        <div class="watch_all" v-if="selectTask" @click="readTasks">
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12.9833 10.0544C12.9833 11.7044 11.6499 13.0377 9.99993 13.0377C8.34993 13.0377 7.0166 11.7044 7.0166 10.0544C7.0166 8.40438 8.34993 7.07104 9.99993 7.07104C11.6499 7.07104 12.9833 8.40438 12.9833 10.0544Z"
                    stroke="#009BF1"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M10.0001 16.9462C12.9418 16.9462 15.6834 15.2129 17.5918 12.2129C18.3418 11.0379 18.3418 9.06287 17.5918 7.88787C15.6834 4.88787 12.9418 3.15454 10.0001 3.15454C7.05845 3.15454 4.31678 4.88787 2.40845 7.88787C1.65845 9.06287 1.65845 11.0379 2.40845 12.2129C4.31678 15.2129 7.05845 16.9462 10.0001 16.9462Z"
                    stroke="#009BF1"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>

            Просмотреть задачи
        </div>
    </main>
</template>

<script>
import { defineComponent } from 'vue';
import Preloader from '@/shared/ui/preloader';
import { findTasks } from '@/axios_requests/find_tasks';
import NotFoundError from '@/shared/ui/error';
import Task from '@/components/TaskItem.vue';
import { sortTaskByDate } from '@/utils/task';
import TaskItemList from '@/components/TaskItemList.vue';
import { plural } from '@/helpers/plural';
import Popup from '@/shared/ui/popup';
import { TaskListSelectMixin } from '@/mixin/TaskListSelectMixin';
import { TaskListMethodMixin } from '@/mixin/TaskListMethodMixin';
import { openActiveTaskList } from '@/utils/task';
import { eventBus } from '@/main';

export default defineComponent({
    name: 'TaskListFound',
    components: { Popup, TaskItemList, Task, NotFoundError, Preloader },
    mixins: [TaskListSelectMixin, TaskListMethodMixin],
    data() {
        return {
            isTasksLoading: true,
            isFoundTasksEmpty: false,
            dataFormated: {},
            taskSelected: null,
            showTaskPart: 'Поиск',
            popoverActive: false,
            taskPlural: '',
            openMenuName: null,
            tasksCount: 0,
            filters_count: 0,
            deepSearchString: this.$store.getters.getSearchTask ?? '',
            date_start: this.$store.getters.getStartTime ?? null,
            date_end: this.$store.getters.getEndTime ?? null,
            date_preset: this.$store.getters.getPickerTime ?? null,
            date_preset_arr: ['Вчера', 'Сегодня', 'Завтра'],
            form_id: this.$store.getters.getFormsId,
            is_closed: this.$store.getters.getIsClosedTask === null ? null : this.$store.getters.getIsClosedTask,
            allForms: this.$store.getters.getAllForms,
            my_task_bool: this.$store.getters.getIsMyTasksBool ?? false,
            selectedIdArr: [],
        };
    },
    async created() {
        if (
            !this.date_end &&
            !this.date_start &&
            this.form_id.length === 0 &&
            this.is_closed === null &&
            !this.deepSearchString &&
            !this.my_task_bool
        ) {
            await this.$router.push({ name: 'Home' });
        }
    },
    async mounted() {
        const header = document.querySelector('.burger-header');
        if (header) {
            header.textContent = this.showTaskPart;
        }

        this.showTaskPart = 'Поиск';

        await this.searchTasks();

        this.taskSelected = this.$store.getters.getTaskSelected;

        if (this.$refs.foo) {
            for (let i = 0; i < this.$refs.foo.length; i++) {
                if (this.taskSelected === this.$refs.foo[i].taskMin.id) {
                    this.$refs.foo[i].isTaskSelected = true;
                    let offset = this.$refs.foo[i].$el.getBoundingClientRect().top + window.pageYOffset - 350;
                    window.scrollTo({ top: offset, behavior: 'smooth' });
                }
            }
        }

        this.$store.dispatch('addTaskSelected', null);

        this.getFilterCount();

        window.scrollTo(0, 0);
    },
    destroyed() {
        const header = document.querySelector('.burger-header');
        if (header) {
            header.textContent = 'Список задач';
        }

        this.$store.dispatch('changeTaskSelectedTab', '');
        this.$store.dispatch('changeAllTaskUpdatedValue', false);
        this.$store.dispatch('changeMyTaskUpdatedValue', false);
        this.$store.dispatch('changeReferrerTaskId', null);
    },
    watch: {
        showTaskPart: function () {
            const header = document.querySelector('.burger-header');
            if (header) {
                header.textContent = this.showTaskPart;
            }
        },
        getTaskSelected: function (newValue) {
            this.taskSelected = newValue;
        },
    },
    methods: {
        getFilterCount() {
            this.filters_count = 0;

            if (this.date_end) {
                this.filters_count += 1;
            }

            if (this.date_start) {
                this.filters_count += 1;
            }

            if (this.form_id && this.form_id.length > 0) {
                this.filters_count += 1;
            }

            if (this.is_closed === 1) {
                this.filters_count += 1;
            }

            if (this.my_task_bool) {
                this.filters_count += 1;
            }
        },
        async applyFilters() {
            // Применяет фильтры повторно
            await this.$store.dispatch('addFilters', {
                date_start: this.date_start,
                date_end: this.date_end,
                form_id: this.form_id,
                closed_task: this.is_closed,
                searchTask: this.deepSearchString,
                date_preset: this.date_preset,
                my_task_bool: this.my_task_bool,
            });

            if (
                this.date_end ||
                this.date_start ||
                this.form_id.length > 0 ||
                this.is_closed !== null ||
                this.deepSearchString ||
                this.my_task_bool
            ) {
                await this.searchTasks();
            } else {
                this.$router.push('/');
            }

            this.popoverActive = false;
        },
        async resetFilters() {
            this.resetDate();

            this.form_id.length = 0;
            this.is_closed = null;
            this.deepSearchString = '';
            this.my_task_bool = false;

            await this.$store.dispatch('addFilters', {
                form_id: this.form_id,
                closed_task: this.is_closed,
                searchTask: this.deepSearchString,
                my_task_bool: this.my_task_bool,
            });
        },

        async clearSearchStringAndGoBack() {
            // Очищает строку поиска
            // Если применены какие-то фильтры то делает запрос без строки ввода
            // Иначе перебрасывает на стартовую страницу
            this.deepSearchString = '';
            this.$store.dispatch('changeSearchTask', '');

            if (!this.date_end && !this.date_start && this.form_id.length === 0 && this.is_closed === null) {
                await this.$router.push({ name: 'Home' });
            } else {
                await this.searchTasks();
            }
        },

        async searchTasks(showLoading = true) {
            if (showLoading) {
                this.isTasksLoading = true;
            }
            this.isFoundTasksEmpty = false;

            const data = {
                contains_string: this.deepSearchString,
                form_id: this.form_id,
                due_date_start: this.$store.getters.getStartTime,
                due_date_end: this.$store.getters.getEndTime,
                is_closed: this.is_closed,
                my_task_bool: this.my_task_bool,
            };

            const res = await findTasks(data);
            if (showLoading) {
                this.isTasksLoading = false;
            }

            this.getFilterCount();

            if (res && res.length === 0) {
                this.isFoundTasksEmpty = true;
                return;
            }

            this.taskSelected = this.$store.getters.getTaskSelected;

            this.dataFormated = sortTaskByDate(res);

            // End UniqDate
            this.isTaskPasses(this.dataFormated, false);
            // Оставляет список с задачами открытым при изменении сокетов
            const key = openActiveTaskList(this.$store.getters.getOpenListName, this.dataFormated);
            if (key) {
                this.dataFormated[key].show = true;
            }

            // Считает не правильно
            this.tasksCount = res.length;
            this.taskPlural = plural(['задача', 'задачи', 'задач'], this.tasksCount);
        },
    },
});
</script>

<style scoped>
.found_task h2 {
    margin-left: 16px;
    padding: 24px 0 14px 0;

    color: var(--black, #333);
    font-size: 18px;
    font-weight: 600;
}

.not-found-wrapper {
    margin: 40px auto;
}

.task-filter {
    margin-bottom: 16px;
}
</style>
