
import View, { ViewOptions } from 'ol/View';
import { ComponentPublicInstance, defineComponent, PropType } from 'vue';
import { Vector, XYZ } from 'ol/source';
import TileLayer from 'ol/layer/Tile';
import Map from 'ol/Map';
import VectorLayer from 'ol/layer/Vector';
import { Feature, Overlay } from 'ol';
import { Point } from 'ol/geom';
import { fromLonLat } from 'ol/proj';
import { Fill, Icon, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';
import { layersData } from '@/components/tracker/model/const';
import LayerControl from '@/components/tracker/ui/entities/LayerControl.vue';
import Popover from '@/components/tracker/ui/shared/popover/popover.vue';
import VMap from '@/components/tracker/ui/shared/Map/Map.vue';

interface MapProps {
    viewOptions: ViewOptions;
    focusObject?: [number, number]; // Координаты объекта для фокуса
}

interface GeoInterface {
    geo_x: number;
    geo_y: number;
}

interface VMapComponent {
    getMap(): HTMLDivElement | undefined;
}

export default defineComponent({
    name: 'YandexMap',
    components: { VMap, LayerControl, Popover },
    props: {
        geo: {
            type: Object as PropType<GeoInterface>,
            required: true,
        },
        decode_address: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            MapConfig: {
                viewOptions: {
                    center: [59.985022, 30.331408],
                    zoom: 6,
                },
            } as MapProps,
            mapRef: null,
            layersData: layersData,
            selectedLayers: 'YandexMap',
            mapIsFullSize: false,
            popoverIsOpen: false,
            currentMap: null as unknown as Map,
        };
    },
    mounted() {
        const projection = '+proj=merc +lon_0=0 +k=1 +x_0=-3800 +y_0=-9360 +ellps=WGS84 +datum=WGS84 +units=m +no_defs';
        // Зарегистрируем проекцию EPSG:3395 для Yandex Maps
        proj4.defs('EPSG:3395', projection);
        register(proj4);

        this.getDefaultMap();
    },
    watch: {
        selectedLayers() {
            if (this.popoverIsOpen) {
                this.getPopoverMap();
            } else {
                this.getDefaultMap();
            }
        },
        popoverIsOpen() {
            setTimeout(() => {
                if (this.popoverIsOpen) {
                    this.getPopoverMap();
                } else {
                    this.getDefaultMap();
                }
            }, 100);
        },
    },
    methods: {
        copyTextHandler(domElement: string) {
            const copyText = this.splitStringByBr(domElement);
            if (!copyText) return;

            this.copyTextToClipboard(copyText);
        },
        splitStringByBr(string: string) {
            if (!string) return;

            if (string.includes('<br>')) {
                const split = string.split('<br>');
                return split[split.length - 1].replace(/(<([^>]+)>)/gi, '');
            }

            return string.replace(/(<([^>]+)>)/gi, '');
        },
        copyTextToClipboard(text: string) {
            navigator.clipboard
                .writeText(text)
                .then(() => {
                    console.log('Текст скопирован в буфер обмена');
                    this.$store.dispatch('changeCopyStatus', true);
                    setTimeout(() => {
                        this.$store.dispatch('changeCopyStatus', false);
                    }, 1300);
                })
                .catch((err) => {
                    console.error('Не удалось скопировать текст в буфер обмена:', err);
                });
        },
        setSelectedLayers(layer: string) {
            this.selectedLayers = layer;
        },
        getPopoverMap() {
            const mapComponent = this.$refs.mapPopover as ComponentPublicInstance<VMapComponent> | null;
            if (!mapComponent) return;

            this.initMap(mapComponent);
        },
        getDefaultMap() {
            const mapComponent = this.$refs.mapDefault as ComponentPublicInstance<VMapComponent> | null;
            if (!mapComponent) return;

            this.initMap(mapComponent);
        },
        initMap(mapComponent: ComponentPublicInstance<VMapComponent>) {
            const mapElement = mapComponent.getMap();
            if (!mapElement) return;

            this.initializeMap(mapElement);
        },
        togglePopover() {
            console.log('toggle');
            this.popoverIsOpen = !this.popoverIsOpen;
        },
        // Функция для изменения размера карты
        resizeMap() {
            this.popoverIsOpen = !this.popoverIsOpen;
            // const size = this.mapIsFullSize
            //     ? {container: '300px', child: '300px'}
            //     : {container: '80vh', child: '100vh'}
            //
            // const map_tracker = document.querySelector('.map_tracker') as HTMLElement | null
            // if (map_tracker) {
            //     map_tracker.style.height = size.container;
            // }
            //
            // const mapElement = this.$refs.map as HTMLDivElement;
            // mapElement.style.height = size.child
        },
        openMapFullSize() {
            this.resizeMap();
            this.mapIsFullSize = !this.mapIsFullSize;

            // Обновляем размеры карты
            setTimeout(() => {
                this.currentMap.updateSize();
            }, 400);
        },
        moveMapToCenter() {
            if (!this.currentMap) {
                console.error('Карта не инициализирована');
                return;
            }

            const view = this.currentMap.getView();
            view.animate({
                center: fromLonLat([this.geo.geo_x, this.geo.geo_y]),
                duration: 500,
                zoom: 18,
            });
        },
        initializeMap(mapElement: HTMLDivElement) {
            if (!mapElement) return;

            mapElement.textContent = '';

            this.MapConfig.focusObject = [this.geo.geo_x, this.geo.geo_y];

            const map = new Map({
                target: mapElement,
                layers: [],
                controls: [],
                view: new View({
                    center: fromLonLat([this.geo.geo_x, this.geo.geo_y]),
                    zoom: this.MapConfig.viewOptions.zoom,
                    maxZoom: 18,
                }),
            });

            layersData.forEach((Layer) => {
                if (this.selectedLayers === Layer.id) {
                    const tileLayer = new TileLayer({
                        source: new XYZ({
                            url: Layer.templates[0],
                            crossOrigin: 'anonymous',
                            projection: Layer.crs || 'EPSG:3857',
                        }),
                    });
                    map.addLayer(tileLayer);
                }
            });

            if (this.MapConfig.focusObject) {
                const vectorLayer = new VectorLayer({
                    source: new Vector(),
                });
                const marker = new Feature({
                    geometry: new Point(fromLonLat(this.MapConfig.focusObject)), // Преобразуем координаты объекта в систему координат карты
                });
                const iconStyle = new Style({
                    image: new Icon({
                        anchor: [0.5, 1],
                        src: require('@/assets/img/Order/Tracker/marker.png'),
                    }),
                });
                const markerStyle = new Style({
                    image: new CircleStyle({
                        radius: 6,
                        fill: new Fill({ color: 'red' }),
                        stroke: new Stroke({ color: 'white', width: 2 }),
                    }),
                });

                marker.setStyle([markerStyle, iconStyle]);

                iconStyle.getImage()?.setScale([parseFloat('0.2'), parseFloat('0.2')]);

                vectorLayer?.getSource()?.addFeature(marker);
                map.addLayer(vectorLayer);

                const point = marker.getGeometry();
                if (point) {
                    map.getView().fit(point, { padding: [50, 50, 50, 50], duration: 1000 }); // Сфокусируем карту на объекте
                }
            }

            this.currentMap = map;

            // Создание контейнера для всплывающего окна
            const popupContainer = document.createElement('div');
            popupContainer.setAttribute('id', 'popup');
            popupContainer.setAttribute('class', 'popup__address--value');

            document.body.appendChild(popupContainer);

            // Создание Overlay для всплывающего окна
            const popupOverlay = new Overlay({
                element: popupContainer,
                autoPan: true,
                positioning: 'bottom-center',
            });
            map.addOverlay(popupOverlay);

            // Добавление обработчика события "click" на карту
            map.on('click', (event) => {
                const feature = map.forEachFeatureAtPixel(event.pixel, function (feature) {
                    return feature;
                }) as any;

                if (feature) {
                    const coordinates = feature.getGeometry()?.getCoordinates(); // Получение координат объекта
                    // HTML-содержимое всплывающего окна
                    popupContainer.innerHTML = `<p>${this.decode_address} <br> <span style="margin-top: 6px; display:block;">${this.geo.geo_x} ${this.geo.geo_y}</span></p>`; // Установка содержимого всплывающего окна
                    popupOverlay.setPosition(coordinates); // Установка положения всплывающего окна

                    const popupHeight = popupContainer.offsetHeight;
                    if (popupHeight < 60) {
                        popupContainer.style.top = '-60px';
                    } else {
                        popupContainer.style.top = `-${popupHeight - 20}px`;
                    }

                    popupContainer.classList.remove('hidden');

                    popupContainer.addEventListener('click', (e: MouseEvent) => {
                        const target = e.target as HTMLElement;
                        if (target.closest('p')) {
                            this.copyTextHandler(target.outerHTML);
                        }
                    });
                } else {
                    popupOverlay.setPosition(undefined); // Скрытие всплывающего окна
                }
            });
        },
    },
});
