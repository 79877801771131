import axios from 'axios'
import {User} from "@/shared/api";
import {HeaderAxios, ResponseAxios} from "@/shared/api";
import HttpClient from "@/app/config/axiosConfig/axiosConfig";

export const getLogin = (id: string) => {
	let responseObj = {} as ResponseAxios
	let headers = {} as HeaderAxios

	const token = localStorage.getItem('current_user')

	if (!token) {
		return (responseObj.error = 'Не авторизован')
	}

	headers['Authorization'] = `Token ${token}`

	return HttpClient({
		method: 'GET',
		url: `/users/${id}`,
		headers,
		withCredentials: true
	})
		.then(resp => {
			try {
				if (resp && resp.status === 200) {
					return resp.data as User
				} else {
				    console.error('Не удалось получить пользователя', id)
                }
			} catch (e) {
				console.log(e)
			}
		})
		.catch(err => {
			return err.response
		})
}
