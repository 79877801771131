<template>
    <div
        class="timer__container"
        :class="[{ 'timer__container--header': isHeader }, { 'timer__container--is-running': !isHeader && isRunning }]"
    >
        <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="timer__icon"
            v-if="!isHeader && !isRunning"
        >
            <path
                d="M14.6668 8.50004C14.6668 12.18 11.6802 15.1667 8.00016 15.1667C4.32016 15.1667 1.3335 12.18 1.3335 8.50004C1.3335 4.82004 4.32016 1.83337 8.00016 1.83337C11.6802 1.83337 14.6668 4.82004 14.6668 8.50004Z"
                stroke="#999999"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M10.4734 10.62L8.40675 9.38671C8.04675 9.17338 7.75342 8.6600005 7.75342 8.24005V5.50671"
                stroke="#999999"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="isHeader">
            <path
                d="M12.8337 7.50002C12.8337 10.72 10.2203 13.3334 7.00033 13.3334C3.78033 13.3334 1.16699 10.72 1.16699 7.50002C1.16699 4.28002 3.78033 1.66669 7.00033 1.66669C10.2203 1.66669 12.8337 4.28002 12.8337 7.50002Z"
                stroke="white"
                stroke-width="1.3125"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M9.16418 9.35503L7.35585 8.27586C7.04085 8.08919 6.78418 7.64003 6.78418 7.27253V4.88086"
                stroke="white"
                stroke-width="1.3125"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
        <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            v-else-if="isRunning && !isHeader"
        >
            <path
                d="M14.6668 8.50004C14.6668 12.18 11.6802 15.1667 8.00016 15.1667C4.32016 15.1667 1.3335 12.18 1.3335 8.50004C1.3335 4.82004 4.32016 1.83337 8.00016 1.83337C11.6802 1.83337 14.6668 4.82004 14.6668 8.50004Z"
                stroke="#00BB34"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M10.4734 10.62L8.40675 9.38671C8.04675 9.17338 7.75342 8.66005 7.75342 8.24005V5.50671"
                stroke="#00BB34"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
        <div>
            <div class="timer" v-if="!isHeader">
                {{ formattedTime }}
            </div>
            <div v-else>
                <div class="timer timer--header">
                    {{ isRunning ? formattedTime : 'Старт' }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { startTimer } from '@/components/timer/model/api/startTimer';
import { stopTimer } from '@/components/timer/model/api/stopTimer';
import { STORAGE_KEY } from '@/utils/const';
import { deleteTaskTimer, getTaskTimer, saveTimersList } from '@/utils/storage/storage-task-timer-method';

export default {
    name: 'Timer',
    props: {
        isHeader: {
            type: Boolean,
        },
        isRunning: {
            type: Boolean,
        },
    },
    data() {
        return {
            minutesPassed: 0, // Таймер в минутах
            intervalId: null,
            unixTimestamp: null,
        };
    },
    computed: {
        isTimerStart() {
            return this.$store.getters.getIsTimerStart;
        },
        isTimerFinish() {
            return this.$store.getters.getIsTimerFinish;
        },
        userId() {
            return this.$store.getters.getCurrentUserId;
        },
        taskId() {
            return this.$store.getters.getTaskSelectedInfo.id;
        },
        timerValue() {
            return this.$store.getters.getTimerValue;
        },
        formattedTime() {
            const hours = Math.floor(this.minutesPassed / 60);
            const minutes = this.minutesPassed % 60;
            return `${this.pad(hours)}:${this.pad(minutes)}`;
        },
    },
    watch: {
        // Следим за изменением свойства в глобальном сторе
        isTimerStart(newVal) {
            if (newVal && this.isHeader) {
                this.headerTimerStart();
                console.log('header timer start');
            }
        },
        isTimerFinish(newVal) {
            if (newVal && this.isHeader) {
                this.headerTimerEnd();
                console.log('header timer stop');
            }
        },
    },
    methods: {
        async getStartTimestamp() {
            await this.$store.dispatch('setTimerError', '');
            const requestData = {
                installer_id: Number(this.userId),
                task_id: this.taskId,
            };
            const response = await startTimer(requestData);
            if (response && response.status === 'success') {
                const startTimeStamp = response.data.start;
                saveTimersList(this.taskId, STORAGE_KEY.timer_value, startTimeStamp);
                this.unixTimestamp = Math.floor(new Date(startTimeStamp).getTime() / 1000);
                this.intervalId = setInterval(() => {
                    this.minutesPassed += 1; // Увеличиваем таймер на 1 минуту
                }, 60000);
                await this.$store.dispatch('startTimer', true);
                await this.$store.dispatch('setIsTimerRunning', true);
                console.log('Таймер запущен');
            } else {
                await this.$store.dispatch('setTimerError', 'Ошибка при запуске');
            }
        },
        async getFinishTimestamp() {
            await this.$store.dispatch('setTimerError', '');
            const requestData = {
                installer_id: Number(this.userId),
                task_id: this.taskId,
            };
            const response = await stopTimer(requestData);
            if (response && response.status === 'success') {
                const finishTimeStamp = response.data.finish - response.data.start;

                this.unixTimestamp = Math.floor(new Date(finishTimeStamp).getTime());
                this.minutesPassed = Math.floor(this.unixTimestamp / 60);
                console.log(this.minutesPassed);
                await this.$store.dispatch('setIsTimerRunning', false);
                await this.$store.dispatch('finishTimer', true);

                await this.$store.dispatch('setFinishTimeValue', this.minutesPassed);
                console.log('Таймер остановлен');

                clearInterval(this.intervalId);
                this.intervalId = null;
                deleteTaskTimer(this.taskId);
            } else {
                await this.$store.dispatch('setTimerError', 'Ошибка при остановке');
            }
        },
        pad(value) {
            return String(value).padStart(2, '0');
        },
        unixToMinutes(unixTimestamp) {
            const currentTime = Math.floor(Date.now() / 1000); // Текущее время в Unix
            const differenceInSeconds = currentTime - unixTimestamp;
            return Math.floor(differenceInSeconds / 60); // Возвращаем разницу в минутах
        },
        headerTimerStart() {
            this.intervalId = setInterval(() => {
                this.minutesPassed += 1;
            }, 60000);
        },
        headerTimerEnd() {
            // this.minutesPassed = this.unixToMinutes(this.unixTimestamp);
            clearInterval(this.intervalId);
            this.intervalId = null;
        },
    },
    mounted() {
        const savedStartTime = getTaskTimer(this.$route.params.id);
        if (savedStartTime) {
            this.unixTimestamp = parseInt(savedStartTime, 10);
            this.minutesPassed = this.unixToMinutes(this.unixTimestamp);
            // this.$store.dispatch('setIsTimerRunning', true);
            // Запускаем таймер, чтобы продолжить отсчет
            this.intervalId = setInterval(() => {
                this.minutesPassed += 1;
            }, 60000); // Интервал 1 минута
        }
        // if (this.isHeader && !savedStartTime) {
        //     this.minutesPassed = Math.floor(new Date(this.timerValue).getTime() / 60);
        // }
    },
};
</script>

<style scoped>
.timer__container {
    display: flex;
    gap: 4px;
    background: #f6f6f6;
    padding: 4px 12px;
    border-radius: 6px;
}

.timer {
    color: #333333;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
}

.timer__container--header {
    background: rgba(13, 127, 190, 0.699999988079071);
}

.timer--header {
    color: #ffffff;
}

.timer__container--is-running {
    background: #edf8f0 !important;
}
</style>
