export interface GeocoderResult {
	full_address: string;
	coordinates: [number, number];
}

class YandexMapError extends Error {
	constructor(message: string) {
		super(message);
		this.name = "YandexMapError";
	}
}


class YandexMapWrapper {
	// @ts-ignore
	private map: ymaps.Map | null = null;
	private containerId: string;
	private container: HTMLElement | null = null;
	public ready: Promise<void>;
	private static SCRIPT_ID = "yandex-maps-script";
	private static API_URL = "https://api-maps.yandex.ru/2.1/";

	constructor(apiKey: string, lang: string = "ru_RU") {
		this.containerId = `ymap-${Math.random().toString(36).substring(2, 15)}`;
		this.ready = this.loadScript(apiKey, lang).then(() => {
			this.createContainer();
			return new Promise<void>((resolve, reject) => {
				// @ts-ignore
				if (!ymaps) {
					reject(new Error("Yandex Maps API не загружен"));
					return;
				}
				// @ts-ignore
				ymaps.ready(() => {
					this.init(this.container!);
					resolve();
				});
			});
		});
	}

	private loadScript(apiKey: string, lang: string): Promise<void> {
		return new Promise((resolve, reject) => {
			// Проверяем, существует ли скрипт
			const existingScript = document.getElementById(YandexMapWrapper.SCRIPT_ID);

			if (existingScript) {
				resolve();
				return;
			}

			const script = document.createElement("script");
			script.id = YandexMapWrapper.SCRIPT_ID;
			script.type = "text/javascript";
			script.src = `${YandexMapWrapper.API_URL}?apikey=${apiKey}&lang=${lang}`;

			script.onload = () => resolve();
			script.onerror = () => reject(new Error("Не удалось загрузить API Яндекс Карт"));

			document.head.appendChild(script);
		});
	}

	private init(container: HTMLElement) {
		// @ts-ignore
		this.map = new ymaps.Map(
			container,
			{
				center: [55.76, 37.64],
				zoom: 7,
			},
			{ autoFitToViewport: "always" },
		);
	}

	private createContainer() {
		this.container = document.createElement("div");
		this.container.id = this.containerId;
		this.container.style.width = "0";
		this.container.style.height = "0";
		this.container.style.opacity = "0";
		this.container.style.position = "absolute";
		this.container.style.left = "-9999px";

		document.body.appendChild(this.container);
		return this.container;
	}

	/**
	 * Выполняет геокодирование для указанного адреса
	 * @param address - Строка адреса для геокодирования
	 * @param maxItems - Максимальное количество результатов для возврата (по умолчанию: 10)
	 * @throws {YandexMapError} Когда карта не инициализирована или адрес недействителен
	 * @returns Promise, возвращающий массив результатов геокодирования
	 */
	public async geocode(address: string, maxItems: number = 10): Promise<GeocoderResult[]> {
		await this.ready; // Ждем завершения инициализации карты

		if (!address.trim()) {
			throw new YandexMapError("Address cannot be empty");
		}

		if (maxItems < 1) {
			throw new YandexMapError("maxItems must be greater than 0");
		}

		return new Promise((resolve, reject) => {
			// @ts-ignore
			ymaps
				.geocode(address, { results: maxItems })
				.then((res: any) => {
					const geoObjects = res.geoObjects;
					const results: GeocoderResult[] = [];
					// Собираем результаты
					geoObjects.each((geoObject: any) => {
						const countryCode = geoObject.properties.get("metaDataProperty").GeocoderMetaData.Address
							.country_code as string;
						if (countryCode.toLowerCase() !== "ru") return;
						const coordinates = geoObject.geometry.getCoordinates();
						const name = geoObject.properties.get("name");
						const description = geoObject.properties.get("description");

						results.push({
							full_address: `${name}, ${description}, Россия`,
							coordinates: coordinates as [number, number],
						});
					});

					for (const result of [...results]) {
						this.addGeoObject(result);
					}

					resolve(results);
				})
				.catch((error: Error) => {
					reject(new YandexMapError(`Geocoding failed: ${error.message}`));
				});
		});
	}

	private addGeoObject(result: GeocoderResult): void {
		if (!this.map) return;
		// @ts-ignore
		const placemark = new ymaps.Placemark(
			result.coordinates,
			{
				balloonContent: `${result.full_address || ""}`,
			},
			{
				preset: "islands#blueIcon",
			},
		);
		this.map.geoObjects.removeAll();
		this.map.geoObjects.add(placemark);
	}

	// Метод для уничтожения скрытой карты
	public destroy(): void {
		if (this.map) {
			this.map.destroy();
			this.map = null;
		}
		if (this.container) {
			this.container.remove();
			this.container = null;
		}
	}
}

export const yandexUtils = new YandexMapWrapper("3189ef21-7581-493c-9711-9b129f7724cd");