import { Commit } from 'vuex';
import { getAllForms } from '@/axios_requests/get_all_form';
import { FormDataType } from '@/shared/api';

type State = {
    date_start: string | null;
    date_end: string | null;
    date_preset: string | null;
    form_id: string[];
    all_forms: FormDataType[];
    filters_count: number;
    closed_task: string | null;
    searchTask: string;
    my_task_bool: boolean;
    referrer_task_id: string | null;
};

type Filters = {
    date_start: string | null;
    date_end: string | null;
    date_preset: string | null;
    form_id: string[];
    closed_task: string | null;
    searchTask: string;
    my_task_bool: boolean;
};

export default {
    state: {
        date_start: null,
        date_end: null,
        date_preset: null,
        form_id: [],
        all_forms: [],
        filters_count: 0,
        closed_task: null,
        searchTask: '',
        my_task_bool: false,
        referrer_task_id: null,
    },
    mutations: {
        addAllForms(state: State, payload: FormDataType[]) {
            state.all_forms = payload;
        },
        addFilters(state: State, payload: Filters) {
            state.date_start = payload.date_start;
            state.date_end = payload.date_end;
            state.date_preset = payload.date_preset;
            state.form_id = payload.form_id;
            state.closed_task = payload.closed_task;
            state.searchTask = payload.searchTask;
            state.my_task_bool = payload.my_task_bool;
        },
        changeSearchTask(state: State, payload: string) {
            state.searchTask = payload;
        },
        changeReferrerTaskId(state: State, payload: string) {
            state.referrer_task_id = payload;
        },
    },
    actions: {
        async addAllForms({ commit }: { commit: Commit }) {
            const forms = await getAllForms();
            commit('addAllForms', forms.forms);
        },
        addFilters({ commit }: { commit: Commit }, payload: Filters) {
            commit('addFilters', payload);
        },
        changeSearchTask({ commit }: { commit: Commit }, payload: string) {
            commit('changeSearchTask', payload);
        },
        changeReferrerTaskId({ commit }: { commit: Commit }, payload: string) {
            commit('changeReferrerTaskId', payload);
        },
    },
    getters: {
        getAllForms(state: State) {
            return state.all_forms;
        },
        getStartTime(state: State) {
            return state.date_start;
        },
        getEndTime(state: State) {
            return state.date_end;
        },
        getFormsId(state: State) {
            return state.form_id;
        },
        getIsClosedTask(state: State) {
            return state.closed_task;
        },
        getIsMyTasksBool(state: State) {
            return state.my_task_bool;
        },
        getSearchTask(state: State) {
            return state.searchTask;
        },
        getPickerTime(state: State) {
            return state.date_preset;
        },
        getReferrerTaskId(state: State) {
            return state.referrer_task_id;
        },
    },
};
