import {HeaderAxios, ResponseAxios, CatalogDataType} from "@/shared/api";
import HttpClient from "@/app/config/axiosConfig/axiosConfig";

export const getCatalogById = (catalog_id: number) => {
    let responseObj = {} as ResponseAxios
    let headers = {} as HeaderAxios

    const token = localStorage.getItem('current_user')

    if (!token) {
        return (responseObj.error = 'Не авторизован')
    }


    headers['Authorization'] = `Token ${token}`

	return HttpClient({
		method: 'GET',
		url: `/catalog/${catalog_id}`,
		withCredentials: true,
        headers: headers
	})
		.then((resp: any) => {
			if (resp.status === 200) {
				return resp.data as CatalogDataType
			} else {
				console.error(`Каталог ${catalog_id} не найден`)
			}
		})
		.catch((err: any) => {
			return err.response
		})

}
