import {FormType} from "@/components/tracker/model/interface";
import {HeaderAxios, ResponseAxios} from "@/shared/api";
import HttpClient from "@/app/config/axiosConfig/axiosConfig";

export const getAllForms = () => {

    let responseObj = {} as ResponseAxios
    let headers = {} as HeaderAxios

    const token = localStorage.getItem('current_user')

    if (!token) {
        return (responseObj.error = 'Не авторизован')
    }

    headers['Authorization'] = `Token ${token}`

    return HttpClient({
        method: 'GET',
        url: `/form/`,
        withCredentials: true,
        headers
    })
        .then((resp: any) => {
            if (resp.status === 200) {
                return resp.data as FormType
            } else {
                console.error(`Формы не найден`)
            }
        })
        .catch((err: any) => {
            return err.response
        })

}
