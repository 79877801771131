import HttpClient from "@/app/config/axiosConfig/axiosConfig";


export const postCommentAttachment = (file: File) => {
	const token = localStorage.getItem('current_user')

	if (!token) {
		return 'Не авторизован'
	}

	const headers = {
		'Content-Type': 'multipart/form-data',
		Authorization: `Token ${token}`
	}

	const formData = new FormData()
	formData.append('file', file)

	return HttpClient({
		method: 'POST',
		url: `/task/attachments`,
		data: formData,
		headers,
		withCredentials: true
	})
		.then((response: { status: number; data: any }) => {
			try {
				if (response.status === 200) {
					return response.data
				} else {
					console.error('Не удалось загрузить файл', file)
				}
			} catch (error: any) {
				return error.response
			}
		})
		.catch((error: { response: any }) => {
			return error.response
		})
}

