<template>
    <div id="app">
        <Header
            v-if="!isPreloaderActive && isHeaderLoaded"
            :logout="logout"
            :cRout="cRout"
            :slideLoaded="slideLoaded"
            :arrowBack="arrowBack"
            :goBack="goBack"
        />

        <Preloader v-if="isPreloaderActive" />

        <VNotification
            v-if="$route.name !== 'Login'"
            :status="connectionLost"
            text="Нет соединения"
            :type="type.connection"
        >
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M14.6666 8.00004C14.6666 11.68 11.6799 14.6667 7.99992 14.6667C4.31992 14.6667 2.07325 10.96 2.07325 10.96M2.07325 10.96H5.08659M2.07325 10.96V14.2934M1.33325 8.00004C1.33325 4.32004 4.29325 1.33337 7.99992 1.33337C12.4466 1.33337 14.6666 5.04004 14.6666 5.04004M14.6666 5.04004V1.70671M14.6666 5.04004H11.7066"
                    stroke="#009BF1"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </VNotification>

        <VNotification :status="canUpdateData" text="Устаревшие данные" :type="type.full_fill">
            <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M9.0013 0.387695C4.40964 0.387695 0.667969 4.12936 0.667969 8.72103C0.667969 13.3127 4.40964 17.0544 9.0013 17.0544C13.593 17.0544 17.3346 13.3127 17.3346 8.72103C17.3346 4.12936 13.593 0.387695 9.0013 0.387695ZM8.3763 5.3877C8.3763 5.04603 8.65964 4.7627 9.0013 4.7627C9.34297 4.7627 9.6263 5.04603 9.6263 5.3877V9.55436C9.6263 9.89603 9.34297 10.1794 9.0013 10.1794C8.65964 10.1794 8.3763 9.89603 8.3763 9.55436V5.3877ZM9.76797 12.371C9.7263 12.4794 9.66797 12.5627 9.59297 12.646C9.50963 12.721 9.41797 12.7794 9.31797 12.821C9.21797 12.8627 9.10964 12.8877 9.0013 12.8877C8.89297 12.8877 8.78464 12.8627 8.68464 12.821C8.58464 12.7794 8.49297 12.721 8.40964 12.646C8.33464 12.5627 8.2763 12.4794 8.23464 12.371C8.19297 12.271 8.16797 12.1627 8.16797 12.0544C8.16797 11.946 8.19297 11.8377 8.23464 11.7377C8.2763 11.6377 8.33464 11.546 8.40964 11.4627C8.49297 11.3877 8.58464 11.3294 8.68464 11.2877C8.88463 11.2044 9.11797 11.2044 9.31797 11.2877C9.41797 11.3294 9.50963 11.3877 9.59297 11.4627C9.66797 11.546 9.7263 11.6377 9.76797 11.7377C9.80964 11.8377 9.83463 11.946 9.83463 12.0544C9.83463 12.1627 9.80964 12.271 9.76797 12.371Z"
                    fill="#FFF"
                />
            </svg>
        </VNotification>

        <VNotification
            :status="$store.getters.getErrorNotificationInfo.status"
            :text="$store.getters.getErrorNotificationInfo.message"
            :type="type.error"
        >
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M9.0013 0.387634C4.40964 0.387634 0.667969 4.1293 0.667969 8.72097C0.667969 13.3126 4.40964 17.0543 9.0013 17.0543C13.593 17.0543 17.3346 13.3126 17.3346 8.72097C17.3346 4.1293 13.593 0.387634 9.0013 0.387634ZM11.8013 10.6376C12.043 10.8793 12.043 11.2793 11.8013 11.521C11.6763 11.646 11.518 11.7043 11.3596 11.7043C11.2013 11.7043 11.043 11.646 10.918 11.521L9.0013 9.6043L7.08464 11.521C6.95964 11.646 6.8013 11.7043 6.64297 11.7043C6.48464 11.7043 6.3263 11.646 6.2013 11.521C5.95964 11.2793 5.95964 10.8793 6.2013 10.6376L8.11797 8.72097L6.2013 6.8043C5.95964 6.56263 5.95964 6.16263 6.2013 5.92097C6.44297 5.6793 6.84297 5.6793 7.08464 5.92097L9.0013 7.83763L10.918 5.92097C11.1596 5.6793 11.5596 5.6793 11.8013 5.92097C12.043 6.16263 12.043 6.56263 11.8013 6.8043L9.88464 8.72097L11.8013 10.6376Z"
                    fill="#EC5555"
                />
            </svg>
        </VNotification>

        <VNotification :status="$store.getters.getCopySuccessStatus" text="Адрес скопированы" :type="type.success">
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.0007 2.38928C5.40934 2.38928 1.66797 6.13066 1.66797 10.722C1.66797 15.3133 5.40934 19.0546 10.0007 19.0546C14.592 19.0546 18.3333 15.3133 18.3333 10.722C18.3333 6.13066 14.592 2.38928 10.0007 2.38928ZM13.9837 8.80545L9.25904 13.5301C9.14238 13.6467 8.98406 13.7134 8.81741 13.7134C8.65076 13.7134 8.49244 13.6467 8.37578 13.5301L6.01763 11.1719C5.77598 10.9303 5.77598 10.5303 6.01763 10.2887C6.25928 10.047 6.65924 10.047 6.90089 10.2887L8.81741 12.2052L13.1004 7.92218C13.3421 7.68054 13.742 7.68054 13.9837 7.92218C14.2253 8.16383 14.2253 8.55547 13.9837 8.80545Z"
                    fill="#00BB34"
                />
            </svg>
        </VNotification>

        <VNotification :status="$store.getters.getWarningStatus" text="Что-то пошло не так" :type="type.error">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M9.0013 0.387634C4.40964 0.387634 0.667969 4.1293 0.667969 8.72097C0.667969 13.3126 4.40964 17.0543 9.0013 17.0543C13.593 17.0543 17.3346 13.3126 17.3346 8.72097C17.3346 4.1293 13.593 0.387634 9.0013 0.387634ZM11.8013 10.6376C12.043 10.8793 12.043 11.2793 11.8013 11.521C11.6763 11.646 11.518 11.7043 11.3596 11.7043C11.2013 11.7043 11.043 11.646 10.918 11.521L9.0013 9.6043L7.08464 11.521C6.95964 11.646 6.8013 11.7043 6.64297 11.7043C6.48464 11.7043 6.3263 11.646 6.2013 11.521C5.95964 11.2793 5.95964 10.8793 6.2013 10.6376L8.11797 8.72097L6.2013 6.8043C5.95964 6.56263 5.95964 6.16263 6.2013 5.92097C6.44297 5.6793 6.84297 5.6793 7.08464 5.92097L9.0013 7.83763L10.918 5.92097C11.1596 5.6793 11.5596 5.6793 11.8013 5.92097C12.043 6.16263 12.043 6.56263 11.8013 6.8043L9.88464 8.72097L11.8013 10.6376Z"
                    fill="#EC5555"
                />
            </svg>
        </VNotification>

        <VNotification :status="$store.getters.getSendMessageSync" text="Отправка сообщений" :type="type.loading">
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.0007 2.38928C5.40934 2.38928 1.66797 6.13066 1.66797 10.722C1.66797 15.3133 5.40934 19.0546 10.0007 19.0546C14.592 19.0546 18.3333 15.3133 18.3333 10.722C18.3333 6.13066 14.592 2.38928 10.0007 2.38928ZM13.9837 8.80545L9.25904 13.5301C9.14238 13.6467 8.98406 13.7134 8.81741 13.7134C8.65076 13.7134 8.49244 13.6467 8.37578 13.5301L6.01763 11.1719C5.77598 10.9303 5.77598 10.5303 6.01763 10.2887C6.25928 10.047 6.65924 10.047 6.90089 10.2887L8.81741 12.2052L13.1004 7.92218C13.3421 7.68054 13.742 7.68054 13.9837 7.92218C14.2253 8.16383 14.2253 8.55547 13.9837 8.80545Z"
                    fill="#009bf1"
                />
            </svg>
        </VNotification>

        <router-view
            v-if="!isPreloaderActive && isProfileLoaded && tasksFilled"
            class="body-app"
            :tasks-object-props="tasksObject"
            :updatedTaskId="taskIdMessage"
            @go-to-full-task="goToFullTask"
            @login="getCabinetTasks"
            :is_task_updated="$store.getters.getTaskStatus"
        />

        <slot v-if="!isPreloaderActive && !isProfileLoaded && cRout === 'Авторизация'">
            <Login />
        </slot>

        <temp-use-pyrus-popup v-if="showTempUsePyrusMsg" @close="showTempUsePyrusMsg = false"></temp-use-pyrus-popup>
        <!-- Если какая то ошибка -->
        <p v-if="errorMsg"></p>

        <div v-if="showUpdateUI && !isPreloaderActive" class="update-dialog">
            <div class="update-dialog__content">Вышла новая версия приложения. Обновить?</div>
            <div class="update-dialog__actions">
                <button class="update-dialog__button update-dialog__button--confirm" @click="accept">Обновить</button>
                <button class="update-dialog__button update-dialog__button--cancel" @click="showUpdateUI = false">
                    Отменить
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import TabBar from '@/components/TabBar.vue';
import Preloader from '@/shared/ui/preloader';
import { APP_LINK, CONSTANT_cRout_activePanel } from '@/constants/constants';
import { TemporaryUnavailable } from './errors/common_errors';
import TempUsePyrusPopup from './components/popups/tempUsePyrusPopup';
import Header from '@/components/Header';
import { getLogin } from '@/axios_requests/get_login';
import Login from '@/views/Login';
import ErrorMessage from '@/components/messages/ErrorMessage';
import { eventBus } from '@/main';
import VNotification from '@/shared/ui/notification';
import { notificationStatus, STORAGE_KEY } from '@/utils/const';
import { TaskApi } from '@/shared/api';
import { LocalStorageService } from '@/utils/storage';
import { checkWhenSwitchTaskTab, getTasksById, updateTaskById } from '@/utils/storage/storage-tasklist-methods';
import { deleteTasksOlderThanOneWeek } from '@/utils/storage/storage-task-methods';
import { deleteCommentsOlderThanOneWeek } from '@/utils/storage/storage-comment-method';
import {
    compareUserTokenFromCacheAndServer,
    getUserDataFromLocalStorage,
    getUserInfoFromLocalStorage,
    getUsersFromLocalStorage,
} from '@/utils/storage/storage-user-method';
import { checkStorageVersion, clearAllLocalStorage, clearLocalStorage } from '@/utils/storage/common-method';
import { getUserById } from '@/store/interaction/userInteraction';
import { openActiveTaskList } from '@/utils/task';
import { getTaskIdFromUrl } from '@/utils/message';
import axios from 'axios';
import { indexDBInit } from '@/utils/indexDB';
import { deleteUnsentMessageById } from '@/utils/storage/unsent-comment-method';
import * as Sentry from '@sentry/vue';

export default {
    name: 'App',
    components: { VNotification, ErrorMessage, Login, Header, TempUsePyrusPopup, TabBar, Preloader },
    data: () => {
        const token = localStorage.getItem('current_user') || null;
        const domain = process.env.VUE_APP_RADAR_SOCKET_URL;
        let socket = null;

        if (token) {
            socket = new WebSocket(`${domain}/ws/events/?token=${token}`);
        }

        return {
            cRout: null,
            isPreloaderActive: true,
            isHeaderLoaded: false,
            tasksObject: [],
            task: {},
            work_object: [],
            connectionLost: false,
            type: notificationStatus,
            errorMsg: null,
            showUpdateUI: false,
            showTempUsePyrusMsg: false,
            isProfileLoaded: false,
            tasksFilled: false,
            slideLoaded: false,
            allCatalogs: [],
            chatSocket: socket,
            domain: domain,
            abortController: new AbortController(),
            isAppOnline: null,
            taskIdMessage: null,
        };
    },
    async created() {
        // Проверяю совпадают ли версии localstorage
        const isVersionCorrect = checkStorageVersion();

        if (!isVersionCorrect) {
            clearAllLocalStorage();

            return;
        }

        if (this.$workbox) {
            this.$workbox.addEventListener('waiting', () => {
                this.showUpdateUI = true;
            });
        }

        // Добавляю переменные в service-worker
        if ('serviceWorker' in navigator) {
            // Обработчик события сообщений от сервис-воркера
            navigator.serviceWorker.addEventListener('message', (event) => {
                if (event.data && event.data.type === 'activate') {
                    console.log('New service worker activated. Reloading page...');
                    // Перезагрузка страницы
                    window.location.reload();
                }

                if (event.data && event.data.type === 'GET_TOKEN') {
                    if (
                        navigator.serviceWorker.controller &&
                        typeof navigator.serviceWorker.controller.postMessage === 'function'
                    ) {
                        const token = localStorage.getItem('current_user') || '';
                        navigator.serviceWorker.controller.postMessage({
                            type: 'setToken',
                            payload: `Token ${token}`,
                        });
                    }
                }
            });

            if (
                navigator.serviceWorker.controller &&
                typeof navigator.serviceWorker.controller.postMessage === 'function'
            ) {
                navigator.serviceWorker.controller.postMessage({
                    type: 'setURL',
                    payload: `${process.env.VUE_APP_TASK_RADAR}`,
                });

                const token = localStorage.getItem('current_user') || '';
                navigator.serviceWorker.controller.postMessage({
                    type: 'setToken',
                    payload: `Token ${token}`,
                });
            }
        }
    },
    async mounted() {
        window.addEventListener('online', this.updateOnlineStatus);
        window.addEventListener('offline', this.updateOnlineStatus);

        this.networkCheckInterval = setInterval(this.updateOnlineStatus, 10_000);

        this.getPath();

        if (!this.$store.getters.getCurrentUserId) {
            this.logout();

            await this.$store.dispatch('changeIsLogin', false);

            if (this.$route.path !== '/login') {
                await this.$router.push('/login');
            }

            this.isPreloaderActive = false;
        } else {
            this.socketEventsInit();
            await this.getCabinetTasks();

            if (this.$store.getters.getCurrentUserId) {
                await this.login();
            }

            setTimeout(() => {
                const tasks = LocalStorageService.getInstance().getArray(STORAGE_KEY.task);
                const comments = LocalStorageService.getInstance().getArray(STORAGE_KEY.comments);

                deleteTasksOlderThanOneWeek(tasks);
                deleteCommentsOlderThanOneWeek(comments);

                LocalStorageService.getInstance().removeItem(STORAGE_KEY.prev_task_id);

                const user = this.$store.getters.getUserData;
                if (user) {
                    Sentry.setUser({ id: user.id, username: user.username });
                }
            }, 5000);
        }

        LocalStorageService.getInstance().saveItem(STORAGE_KEY.activeTab, this.$store.getters.getActiveTab);

        window.addEventListener('click', (event) => {
            const target = event.target;
            // Проверка, является ли целью элемент ссылки
            if (target.tagName === 'A') {
                const url = target.href;
                const containsAppLink = APP_LINK.some((link) => url.includes(link));
                const linkOnTask = url.includes('/task/');
                // Проверка URL-адреса и выполнение вашей логики
                if (containsAppLink && linkOnTask) {
                    console.log('Внутренний url');

                    // Получаю id из текущей задачи, что бы сохранить его
                    const currentLink = this.$route.params;

                    if (currentLink && currentLink.id) {
                        LocalStorageService.getInstance().saveItem(STORAGE_KEY.prev_task_id, currentLink.id);
                    }

                    const id = getTaskIdFromUrl(url);
                    if (id) {
                        LocalStorageService.getInstance().saveItem(
                            STORAGE_KEY.activeTab,
                            this.$store.getters.getActiveTab
                        );

                        this.$router.push({
                            name: 'TaskFull',
                            params: { id },
                        });
                    }
                }
            }
        });

        await indexDBInit();

        // Смотрит на сообщения которые отправлены из сервис воркера
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.addEventListener('message', async (event) => {
                if (event.data.type === 'MESSAGE_SEND_SUCCESS') {
                    if (!this.$route.path.includes('/task/')) {
                        await this.$store.dispatch('changeSendMessageStatus');
                    }

                    // Посмотреть есть ли это сообщение в partMessages, и если нет то добавить
                    await this.$store.dispatch('validateMessagePart', event.data.data.data);

                    this.taskIdMessage = {
                        taskId: event.data.data.id,
                        message: event.data.data.data.text,
                    };

                    await this.$store.dispatch('changeLastMessageInTask', this.taskIdMessage);
                    this.tasksObject = this.tasksObject.map((task) => {
                        if (Number(task.id) === Number(this.taskIdMessage.taskId)) {
                            task.comment = this.taskIdMessage.message;

                            // Обновление задач и в сторе
                            updateTaskById(task);
                        }

                        return task;
                    });

                    console.log(this.taskIdMessage);

                    await this.$store.dispatch('deleteNotSendMessageById', event.data.data.data.id);
                    deleteUnsentMessageById(event.data.data.id, event.data.data.data.id);
                }
            });
        }

        this.requestNotificationPermission();

        window.addEventListener('popstate', this.goBackHistory);
    },
    destroyed() {
        window.removeEventListener('online', this.updateOnlineStatus);
        window.removeEventListener('offline', this.updateOnlineStatus);

        clearInterval(this.networkCheckInterval);
    },
    computed: {
        arrowBack() {
            return CONSTANT_cRout_activePanel.includes(this.cRout);
        },
        canUpdateData() {
            return this.$store.getters.getCanUpdateData;
        },
    },
    watch: {
        $route: async function (to, from) {
            this.getPath();

            if (this.cRout === 'Список задач' && from.name !== 'Login') {
                console.log('change app');
                await this.$store.dispatch('changeActiveTab', 'task');

                await this.getCabinetTasks();
            }

            if (from.name === 'Login') {
                this.connectionLost = false;

                this.getPath();

                if (this.$store.getters.getCurrentUserId) {
                    await this.login();
                } else {
                    this.logout();
                    await this.$router.push({ name: 'Login' });

                    return;
                }

                await this.getCabinetTasks();

                this.socketEventsInit();
            }
        },
    },
    methods: {
        async updateOnlineStatus() {
            const prev = this.isAppOnline;

            this.isAppOnline = await this.checkNetworkStatus();

            console.log(`Network status changed: ${this.isAppOnline ? 'online' : 'offline'}`);

            if (this.isAppOnline) {
                console.log('🟢 Подключение к интернету восстановлено.');

                if (prev !== this.isAppOnline) {
                    navigator.serviceWorker.controller?.postMessage({
                        type: 'SYNC',
                    });
                }

                this.connectionLost = false;
            } else {
                console.log('🔴 Подключение к интернету потеряно.');
                this.connectionLost = true;
            }

            await this.$store.dispatch('changeOnlineStatus', !this.connectionLost);
        },
        async checkNetworkStatus() {
            try {
                const response = await fetch(`${process.env.VUE_APP_PUBLIC_PATH}ping.txt`, {
                    method: 'HEAD',
                    cache: 'no-store', // Отключение кэширования
                    headers: {
                        'Cache-Control': 'no-cache, no-store, must-revalidate',
                        Pragma: 'no-cache',
                        Expires: '0',
                    },
                });
                return response.ok;
            } catch (error) {
                return false;
            }
        },
        socketEventsInit() {
            console.log('socketEventsInit');

            const token = localStorage.getItem('current_user') || null;

            if (!this.chatSocket) {
                const domain = process.env.VUE_APP_RADAR_SOCKET_URL;

                this.chatSocket = new WebSocket(`${domain}/ws/events/?token=${token}`);

                if (!this.chatSocket) return;
            }

            if (this.chatSocket.readyState === WebSocket.CLOSED) {
                const token = localStorage.getItem('current_user') || null;
                if (token) {
                    this.chatSocket = new WebSocket(`${this.domain}/ws/events/?token=${token}`);
                }
            }

            this.chatSocket.onopen = () => {
                console.log('[open] Соединение установлено');

                setTimeout(() => {
                    this.connectionLost = false;
                    this.$store.dispatch('changeOnlineStatus', !this.connectionLost);
                }, 1500);
            };

            this.chatSocket.onmessage = this.socketMessageEvent.bind(this);
            this.chatSocket.onclose = this.socketCloseEvent.bind(this);
            this.chatSocket.onerror = (error) => {
                console.log(`[error]`, error);
            };
        },
        async socketMessageEvent(event) {
            console.log(`[message] !!!Данные получены с сервера `);
            const msg = JSON.parse(event.data);
            eventBus.$emit('socketEvent', msg);
            eventBus.$emit('socketEventTaskList', msg);
        },
        socketCloseEvent(event) {
            // NOTIFICATION
            this.connectionLost = true;
            this.$store.dispatch('changeOnlineStatus', !this.connectionLost);

            if (event.wasClean) {
                console.log(`[close] Соединение закрыто чисто, код: ${event.code} причина: ${event.reason}`);
            } else {
                console.log('[close] Соединение прервано');
            }

            console.log('[connection] Попытка возобновить соединение');

            setInterval(() => {
                if (this.isAppOnline) {
                    if (this.chatSocket.readyState === WebSocket.OPEN) {
                        return;
                    }

                    this.socketEventsInit();
                }
            }, 5000);
        },
        goBackHistory() {
            this.clearFilter();
        },
        async requestNotificationPermission() {
            try {
                const permission = await window.Notification.requestPermission();
                if (permission !== 'granted') {
                    console.log('Permission not granted for Notification');
                }
            } catch (err) {
                console.log('Notification permission request failed');
            }
        },
        goBack() {
            const taskId = LocalStorageService.getInstance().getItem(STORAGE_KEY.prev_task_id);
            console.log('ROUTER@', this.$router.history.current.name);
            console.log('TASK_ID', taskId);

            if (this.$router.history.current.name === 'TaskFull' && taskId && taskId.length !== 0) {
                this.$router.replace({
                    name: 'TaskFull',
                    params: {
                        id: taskId,
                    },
                });

                LocalStorageService.getInstance().removeItem(STORAGE_KEY.prev_task_id);
                window.location.reload();
                window.scrollTo({ top: 0 });

                return;
            }

            // Если переход с задачи и при этом что-то есть в строке поиске, то возвращаю на страницу с поиском
            if (
                this.$router.history.current.name === 'TaskFull' &&
                (this.$store.getters.getSearchTask ||
                    this.$store.getters.getEndTime ||
                    this.$store.getters.getStartTime ||
                    (this.$store.getters.getFormsId && this.$store.getters.getFormsId.length > 0))
            ) {
                this.$router.push('/found');

                // Если перешли в профиль с открытой задачи, то перекидываю обратно в актуальную задачу
            } else if (this.$router.history.current.name === 'Profile' && this.$store.getters.getTaskSelected) {
                this.$router.push({
                    name: 'TaskFull',
                    params: {
                        task: this.$store.getters.getTaskSelectedInfo,
                        id: this.$store.getters.getTaskSelected,
                    },
                });
            } else if (this.$router.history.current.name === 'FoundTasks') {
                this.clearFilter();

                if (this.$store.getters.getReferrerTaskId) {
                    this.$router.push(`/task/${this.$store.getters.getReferrerTaskId}`);
                    this.$store.dispatch('changeReferrerTaskId', null);
                } else {
                    this.$router.push('/');
                }
            } else {
                this.$router.push('/');
            }
        },
        clearFilter() {
            this.date_start = null;
            this.date_end = null;
            this.date_preset = null;
            this.form_id = [];
            this.is_closed = null;

            this.$store.dispatch('addFilters', {
                date_start: this.date_start,
                date_end: this.date_end,
                form_id: this.form_id,
                closed_task: this.is_closed,
                searchTask: '',
                date_preset: this.date_preset,
            });
        },
        async accept() {
            this.showUpdateUI = false;
            await this.$workbox.messageSW({ type: 'SKIP_WAITING' });
        },
        async login(loginNow = false) {
            try {
                // Если пользователь только что вошел в аккаунт
                if (loginNow) {
                    const userId = this.$store.getters.getCurrentUserId;

                    await this.$store.dispatch('addUsersFromCache');

                    const userInfo = getUserById(Number(userId));

                    this.$store.commit('addCurrentUserData', userInfo);

                    this.isHeaderLoaded = true;
                    this.isProfileLoaded = true;

                    return;
                }

                // Сначала достаю пользователя из кэша
                const usersUpdated = await this.getUserFromCache();

                const res = await getLogin(this.$store.getters.getCurrentUserId);
                const isUserCacheLoginCorrect = compareUserTokenFromCacheAndServer(res);

                // Если старые данные и новые не совпадают, то выходим из аккаунта
                if (!isUserCacheLoginCorrect) {
                    this.logout();
                    await this.$router.push({ name: 'Login' });

                    return;
                }

                if (!usersUpdated) {
                    await this.$store.dispatch('addUsers');
                }

                this.$store.dispatch('addCurrentUserData', res);

                this.slideLoaded = true;
                this.isHeaderLoaded = true;
                this.isProfileLoaded = true;
            } catch (error) {
                // Handle any errors
                console.error('Error fetching login data:', error);
            }
        },
        logout() {
            if (this.chatSocket && this.chatSocket.readyState === WebSocket.OPEN) {
                this.chatSocket.close();
            }

            this.$store.dispatch('putTaskUpdated', false);

            this.$store.dispatch('changeIsLogin', false);

            this.$store.dispatch('changeAllTaskUpdatedValue', false);
            this.$store.dispatch('changeMyTaskUpdatedValue', false);

            this.$store.dispatch('exitFromAccount');

            clearLocalStorage();
        },
        async getUserFromCache() {
            // Информация о текущем пользователе
            const { userId, token } = getUserInfoFromLocalStorage();
            const userData = getUserDataFromLocalStorage();

            // Все пользователи
            const cacheUsers = getUsersFromLocalStorage();

            if (cacheUsers === 'Не авторизован') {
                this.logout();
                await this.$router.push({ name: 'Login' });

                return;
            }

            if (userData && userId && token) {
                await this.$store.dispatch('addUsers', cacheUsers);

                this.$store.dispatch('addCurrentUserData', userData);

                this.isHeaderLoaded = true;
                this.isProfileLoaded = true;

                return true;
            }

            return false;
        },
        async getCabinetTasks() {
            if (!this.$store.getters.getTaskStatus) {
                let user = this.$store.getters.getCurrentUser;

                if (!user) {
                    await this.$store.dispatch('changeIsLogin', false);

                    if (this.$route.path !== '/login') {
                        await this.$router.push('/login');
                    }

                    this.isPreloaderActive = false;
                } else {
                    this.isPreloaderActive = true;

                    try {
                        await this.handlePreloadTasks();
                        await this.$store.dispatch('changeIsLogin', true);

                        if (this.$store.getters.getCurrentUserId) {
                            await this.login(true);
                        }

                        if (this.$route.name === 'TaskFull') {
                            // Если загрузка задачи
                            this.isPreloaderActive = false;
                            this.tasksFilled = true;
                            console.log('get task by id');
                        }

                        if (this.$route.name === 'Home') {
                            const response = await TaskApi.getTasks(user, 'my', this.abortController.signal);

                            await this.handleTaskApiResponse(response);
                        }
                    } catch (err) {
                        console.error('An error occurred:', err);
                    }
                }
            } else {
                this.tasksObject = this.$store.getters.getTasks;
                this.tasksFilled = true;
            }
        },
        async handlePreloadTasks() {
            const preloadTasks = LocalStorageService.getInstance().getArray(STORAGE_KEY.taskList);
            const cacheData = checkWhenSwitchTaskTab(preloadTasks.timestamp, preloadTasks.tasks_id);

            if (cacheData && cacheData.length > 0) {
                this.tasksObject = getTasksById(preloadTasks.tasks, cacheData);
                this.tasksFilled = true;
                this.isPreloaderActive = false;

                await this.$store.dispatch('changeMyTasksCount', this.tasksObject.length);
            }
        },
        async handleTaskApiResponse(response) {
            this.isPreloaderActive = false;

            if (response && response.error === 401) {
                await this.$store.dispatch('changeIsLogin', false);

                if (this.$route.path !== '/login') {
                    await this.$router.push('/login');
                }
            } else if (response.error) {
                await this.$store.dispatch('changeIsLogin', true);

                this.errorMsg = `Ошибка ${response.error}`;
            } else {
                await this.$store.dispatch('changeIsLogin', true);

                this.errorMsg = null;
                this.tasksObject = response;
                this.tasksFilled = true;

                await this.$store.dispatch('changeMyTasksCount', this.tasksObject.length);
                //
                // await this.$store.dispatch('addTasks', {
                // 	tasks: response,
                // 	tab: 'my'
                // });
                // await this.$store.dispatch("rerenderTasksList", true)
                await this.$store.dispatch('putTaskUpdated', true);
            }
        },

        async goToFullTask(taskId) {
            try {
                await this.$router.push({
                    name: 'TaskFull',
                    params: {
                        id: taskId,
                    },
                });
            } catch (e) {
                console.warn(e);
                if (!(e instanceof TemporaryUnavailable)) {
                    throw e;
                }
                this.showTempUsePyrusMsg = true;
            }
        },
        getPath() {
            let currPath;
            switch (this.$router.currentRoute.name) {
                case 'Home':
                    currPath = 'Список задач';
                    break;
                case 'TaskFull':
                    currPath = 'Задача';
                    break;
                case 'Login':
                    currPath = 'Авторизация';
                    break;
                case 'Chat':
                    currPath = 'Чат';
                    break;
                case 'FoundTasks':
                    currPath = 'Поиск';
                    break;
                case 'Profile':
                    currPath = 'Настройки профиля';
                    break;
                case 'Guide':
                    currPath = 'Инструкция';
                    break;
                case 'NotFound':
                    currPath = 'Не найдено';
                    break;
            }
            this.cRout = currPath;
        },
    },
};
</script>

<style>
body {
    background-color: #f6f6f6;
    color: #333;
    font-size: 16px !important;
}

.body-app {
    margin-top: 50px;
}

.burger {
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 20px;
    height: 50px;
    background-color: #009bf1;
    text-align: center;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
}

.burger-header {
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 23px;
    color: #ffffff;
    z-index: 100;
}

.back-arrow {
    z-index: 100;
    margin-right: 8px;
}

.update-dialog {
    position: fixed;
    left: 50%;
    bottom: 64px;
    transform: translateX(-50%);
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    padding: 12px;
    max-width: 576px;
    color: white;
    background-color: #2c3e50;
    text-align: left;

    z-index: 21;
}

.update-dialog__actions {
    display: flex;
    margin-top: 8px;
}

.update-dialog__button {
    margin-right: 8px;
}

.update-dialog__button--confirm {
    margin-left: auto;
}
</style>
